import { createContext, useEffect, useState, useCallback } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
  FieldValue                               
} from "firebase/firestore";

export const AuthContext = createContext();


const fetchUserData = (sessionId, setUserData, setIsDataLoaded, isAnonymous) => {
  const unsubscribeUser = onSnapshot(doc(db, 'users', sessionId), async (docSnapshot) => {
    if (docSnapshot.exists()) {
      console.log('User data loaded:', docSnapshot.data()); // Debugging log
      setUserData(docSnapshot.data());
      setIsDataLoaded(true); // Set isDataLoaded to true when data is loaded
    } else {
      console.error('No such user!', "sessionid", sessionId);
      
      if (isAnonymous) {
        // Log out the anonymous user here
        // You can implement your logout logic for anonymous users
        // For example, if you're using Firebase Authentication:
        auth.signOut();
      }
      
      setIsDataLoaded(false); // Set isDataLoaded to false in case of error
    }
  }, (error) => {
    console.error('Error fetching user data:', error);
    setIsDataLoaded(false); // Set isDataLoaded to false in case of error
  });

  return unsubscribeUser;
};


export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [noUser, setNoUser] = useState(false); // New state to track if there's no user
  const [sessionId, setSessionId] = useState(currentUser ? currentUser.uid : localStorage.getItem('tempSessionId'));
  const [isDataLoaded, setIsDataLoaded] = useState(false); // New state for tracking data load status
  const handleAuthStateChange = useCallback((user) => {
    setCurrentUser(user);
    if (user) {
      setSessionId(user.uid);
      setNoUser(false); // Reset noUser state when a user is found
    } else {
      setNoUser(true); // Set noUser to true if no user is found
      const tempSessionId = localStorage.getItem('tempSessionId');
      if (tempSessionId) {
        setSessionId(tempSessionId);
      }
    }
  }, []);
  // Listen for changes in localStorage
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.type === 'storage' && !currentUser) {
        const newTempSessionId = localStorage.getItem('tempSessionId');
        if (newTempSessionId) {
          setSessionId(newTempSessionId);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [currentUser]);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, handleAuthStateChange);
    return () => unsubscribeAuth();
  }, [handleAuthStateChange]);

  useEffect(() => {
    if (!sessionId || noUser) return; // Add noUser to the condition
  
    const isAnonymous = currentUser ? currentUser.isAnonymous : false;
    const unsubscribeUser = fetchUserData(sessionId, setUserData, setIsDataLoaded, isAnonymous);
    return () => {
      if (typeof unsubscribeUser === 'function') {
        unsubscribeUser();
      }
    };
  }, [sessionId, currentUser, noUser]);

  return (
    <AuthContext.Provider value={{ currentUser, userData, isDataLoaded, noUser }}> 
      {children}
    </AuthContext.Provider>
  );
};