import React, { useState, useRef, useEffect, useContext} from 'react';
import '../styles/ScrollableTopics.scss';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { truncate } from 'fs';
import { AuthContext } from '../context/AuthContext';
import { ChatContext } from '../context/ChatContext';
import VoicePopup from './VoicePopup';
const topics = [
  { name: 'truth or dare', emoji: '🕵️‍♂️' },
  { name: 'kinky fetishes', emoji: '🤖' },
  { name: 'gender identity', emoji: '🧡' },
  { name: 'sexy outfits', emoji: '🔥' },
  { name: 'sexual fantasies', emoji: '💭' },
  { name: 'hot celebrities', emoji: '🌟' },
  { name: 'music', emoji: '🎶' },
  { name: 'friendship', emoji: '👥' },
  { name: 'marriage', emoji: '👫' },
  { name: 'sex positions', emoji: '😈' },
  { name: 'relationships', emoji: '💘' },
  { name: 'dating', emoji: '💋' },
];
const categories = [
  { name: 'Gifts', topics: [/* Array of gifts topics or items */] },
  { name: 'Dates', topics: [/* Array of dates topics or items */] },
  { name: 'Topics', topics: [/* Your existing topics array */] },
];

const ScrollableTopics = ({ onTopicClick, onClickStories, onClickDiary,onClickVideos, characterData}) => {
  const {currentUser} = useContext(AuthContext)
  const {setDisplayType, setShowSignUp, voiceId, setVoiceId} = useContext(ChatContext)
  const [canScroll, setCanScroll] = useState({ left: false, right: true });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const containerRef = useRef(null);
  const [isContainerAvailable, setIsContainerAvailable] = useState(false);
  const [voicePopupOpen, setVoicePopupOpen] = useState(false)
  const checkScrollButtons = () => {
    const container = containerRef.current;
    if (!container) return;

    const maxScrollLeft = container.scrollWidth - container.clientWidth;
    setCanScroll({
      left: container.scrollLeft > 0,
      right: container.scrollLeft < maxScrollLeft -1,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', checkScrollButtons);
    return () => {
      window.removeEventListener('resize', checkScrollButtons);
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const onScroll = () => checkScrollButtons();
      container.addEventListener('scroll', onScroll, { passive: true });

      checkScrollButtons(); // Initial check

      return () => {
        container.removeEventListener('scroll', onScroll);
      };
    }
    console.log("characterData", characterData)
  }, [selectedCategory]);
  const scroll = (direction) => {
    const container = containerRef.current;
    if (!container) return;
  
    const scrollAmount = direction === 'left' ? -container.clientWidth : container.clientWidth;
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };
  const selectCategory = (categoryName) => {
    setSelectedCategory(categories.find(category => category.name === categoryName));
  };

  const closeTopics = () => {
    setSelectedCategory(null);
  };
  const handleClickWithoutLogin = () => {
    setDisplayType("features")
    setShowSignUp(true)
}
const onClickVoices = () => {
setVoicePopupOpen(true)
}
  return (
    <>
<div className="scrollable-topics">
      {selectedCategory === null && (
        <div className="category-buttons">
          <button className='topic-button' onClick={currentUser.isAnonymous ? handleClickWithoutLogin :onClickVoices}>Voices 🎤</button>
          {characterData && characterData.video && false ? (<button className='topic-button' onClick={currentUser.isAnonymous ? handleClickWithoutLogin : onClickVideos}>Videos 📸</button>) : (<button className='topic-button' onClick={currentUser.isAnonymous ? handleClickWithoutLogin : onClickDiary}>Diary 📕</button>)}
          {/* <button className='topic-button' onClick={currentUser.isAnonymous ? handleClickWithoutLogin : onClickStories}>Stories 📖</button>*/}
          {/*<button className='topic-button' onClick={currentUser.isAnonymous ? handleClickWithoutLogin :onClickStories}>Stories 🎗️</button>*/}
          {/*<button className='topic-button' onClick={() => selectCategory('Topics')}>Topics 🤔</button>*/}
        </div>
      )}
      {selectedCategory && selectedCategory.name === 'Topics' && (
  <>
    <div className="close-button" onClick={closeTopics}>
      <FontAwesomeIcon icon={faTimes} />
    </div>

    {canScroll.left && (
      <div className="chevron left" onClick={() => scroll('left')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    )}

    <div className="topics-container" ref={containerRef}>
      {topics.map((topic) => (
        <button key={topic.name} className="topic-button" onClick={() => onTopicClick(topic.name)}>
          {topic.emoji} {topic.name.charAt(0).toUpperCase() + topic.name.slice(1)}
        </button>
      ))}
    </div>

    {canScroll.right && (
      <div className="chevron right" onClick={() => scroll('right')}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    )}
  </>
)}
    </div>
    <VoicePopup isOpen={voicePopupOpen} onClose={()=> setVoicePopupOpen(false)}/>
    </>
  );
};

export default ScrollableTopics;