import React, { useRef, useContext, useEffect, useState } from "react";
import Footer from "../../components/Common/Footer"
import Nav from "../../components/Common/MainNav"

const TOS = () => {

  useEffect(() => {
  });

  return (
    <div>
<Nav/>
    
    <div className='simpleContent'>
<div className="content">
<div>
<p><b><h3>Terms
of Use</h3></b></p>

<p><span>Your
account will not be completed until you have read and affirmatively accepted
our Terms of Use and our Privacy Policy located at&nbsp;</span><a
href="http://www.onlychat.io/privacy-policy"><span>www.onlychat.io/privacy-policy</span></a><span
>&nbsp;and by checking the
applicable boxes on the account creation page.</span></p>

<p><b><span>1.
Acceptance</span></b></p>

<p>Onylchat.io is a website operated solely by M.T.Kashi ("Onylchat"). As a sole proprietorship, Onylchat's responsibilities and operations are managed exclusively by M.T.Kashi. References to “<b><i>we</i></b>,” “<b><i>us</i></b>,” or “<b><i>our</i></b>” on the <a href="https://www.onylchat.io/">Onylchat.io</a> website and its associated properties solely pertain to M.T.Kashi, in the capacity of the sole proprietor and operator of Onylchat.</p>


<p><span>By
using, downloading, purchasing and/or visiting the Chatrooms and other contents
within the onylchat website, you agree to the Terms of Use contained here and
to the Website’s privacy policy (</span><a
href="https://www.onylchat.io/privacy-policy/"><span>www.Onylchat.io/privacy-policy</span></a><span
>) and all future amendments
and modifications (collectively referred to as the &quot;Agreement&quot;). If
you do not agree to be bound the Terms of Use contained here, then do not
visit the Website.</span></p>

<p><span>YOU
CONSENT TO ENTERING THESE TERMS ELECTRONICALLY, AND TO STORAGE OF RECORDS
RELATED TO THESE TERMS IN ELECTRONIC FORM.</span></p>

<p><span>The
Terms of Use of this Agreement are subject to change by the Website at any time
in its sole discretion and you acknowledge that by using the  Chatrooms and
Website you are agreeing to be bound by all modifications, changes and/or
revisions that are disuseed on our website at the time you access it. If you do
not accept to be bound by any and all modifications, changes and/or revisions
of this Agreement do not use the Website.</span></p>

<p><span>The
Terms of Use contained here apply to all users of the Website whether a
'visitor' or a ‘member’ (collectively referred to as &quot;you&quot;,
&quot;users&quot;, “you’re&quot;) and you are only authorized to use the
Website if you agree to abide by all applicable laws and be legally bound by
the Terms of Use of this Agreement.</span></p>

<p><span>When using the
Website, you shall be subject to all disuseed rules, community guidelines, or
policies. Such rules, guidelines, and policies are hereby incorporated by
reference into this Agreement. We may also offer other websites that are
governed by different Terms of Use.</span></p>

<p><b><span>1.1 Ability
to Accept Terms of Use</span></b></p>

<p><span>You affirm that
you are at least 18 years of age or the age of majority in the jurisdiction you
are accessing the Website from, and are fully able and competent to enter into
the terms, conditions, obligations, affirmations, representations, and
warranties set forth in these Terms of Use, and to abide by and comply with
these Terms of Service. If you are under 18 or the applicable age of majority,
you are not to use the Onylchat.io website. You also represent that the
jurisdiction from which you access the Website does not prohibit the receiving
or viewing of sexually explicit content.</span></p>

<p><b><span>2.
Description of Services</span></b></p>

<p><span>Our Website offers interactive features including Chatrooms. We are committed to responsible content management. We prioritize the privacy and security of our users, implementing robust measures to safeguard personal information and ensure compliance with applicable privacy laws. User interactions with the Website, including content viewing are governed by our comprehensive Terms of Use, ensuring a safe, lawful, and respectful environment for all members.</span></p>

<p><span>The Website is
for your personal use and shall not be used for any commercial endeavor. Any
illegal and/or unauthorized use of the Website is prohibited including but not
limited to collecting usernames and e-mail addresses for sending unsolicited
emails or unauthorized framing or linking to the foresaid Website is
prohibited.</span></p>

<p><span>You may access
portions of the Website without registering. However, in order to access some
portions and features of the Website and use use, purchase or download the  Chatrooms,
you will be required to register with and sign into the Website. You are
responsible for maintaining the confidentiality of your password and other
information used to register and sign in to the Website, and you are fully
responsible for all activities that occur under this password and username.
Your username will be publicly available and used in Chatrooms to publicly
associate you with your account.</span></p>

<p><b><span>3. User
Conduct</span></b></p>

<p><b><span>3.1 You acknowledge and agree that you shall not:</span></b></p>

<ul>
 <li><span>engage in activities that violate any applicable laws or regulations, including those related to data privacy, such as GDPR, CCPA, or any other regional data protection laws;</span></li>
 <li><span>introduce malicious software or code designed to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment, or to damage or obtain unauthorized access to any system, data, password, or other information of the Website or any third party;</span></li>
 <li><span>use the Website to collect, store, or process personal data about other users without their explicit consent or in violation of our Privacy Policy;</span></li>
 <li><span>utilize any automated means, including bots, scrapers, or data mining tools, to collect, access, process, or use data from the Website or any user without authorization;</span></li>
 <li><span>alter or modify any part of the Website or its content, or attempt to gain unauthorized access to the Website, its user accounts, computer systems, or networks connected to the Website through hacking, password mining, or any other means;</span></li>
 <li><span>exploit any bugs, glitches, vulnerabilities, or unintended features of the Website to gain unauthorized access or to compromise the integrity, functionality, or performance of the Website or the privacy of its users;</span></li>
 <li><span>use the Website in any manner that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the Website, or that could damage, disable, overburden, or impair the functioning of the Website in any manner;</span></li>
 <li><span>use any robot,
     spider, scraper, or other automated means to access the Website for any
     purpose;</span></li>
 <li><span>take any
     action that imposes, or may impose an unreasonable or disproportionately
     large load on our infrastructure;</span></li>
 <li><span>interfere or
     attempt to interfere with the proper working of the Website or any
     activities conducted on the Website, or bypass any measures we may use to
     prevent or restrict access to the Website;</span></li>
 <li><span>use our
     Website to harm anyone or to cause offence to or harass any person;</span></li>
 <li><span>create more
     than one account per platform to access our Website;</span></li>
 <li><span>use another
     person or entity’s email address in order to access or sign up to use our
     Website;</span></li>
     <li><span>Ensure all user data is treated in accordance with data protection laws, and is not used for any unauthorized purposes. Users must have explicit permission to use and share any content.</span></li>
<li><span>Respect the privacy and confidentiality of user data. Any collection, use, and sharing of personal information will be clearly disclosed in our Privacy Policy and will comply with applicable data protection laws.</span></li>
<li><span>Maintain robust security measures to protect user data from unauthorized access, disclosure, alteration, and destruction. Regularly review and update our security practices to ensure the highest level of data protection.</span></li>
<li><span>Provide users with access to their own data and allow them to correct, amend, or delete personal information, except where we are required to retain it by law or for legitimate business purposes.</span></li>
<li><span>Inform users about the use of cookies and similar tracking technologies on our Website in a clear and transparent manner, as detailed in our Cookie Policy.</span></li>
<li><span>Respond promptly to user requests, inquiries, and complaints regarding their privacy and personal data, adhering to regulatory timeframes for such responses.</span></li>
<li><span>Ensure that all data transfers, especially across borders, comply with international data protection standards and legal requirements.</span></li>
<li><span>Regularly review and update our privacy practices to comply with evolving legal standards and best practices in data protection and privacy.</span></li>
<li><span>Obtain explicit consent from users for any processing of sensitive personal data, as defined by applicable data protection laws.</span></li>
<li><span>Do not engage in the sale or unauthorized sharing of user data with third parties for marketing, advertising, or other non-essential purposes without user consent.</span></li>
<li><span>Provide clear and accessible information to users about their rights regarding their data, including the right to access, rectify, delete, or object to the processing of their data.</span></li>
 <li><span>interfere
     with or disrupt our Website or servers or networks that provide our
     services;</span></li>
 <li><span>attempt to
     decompile, reverse engineer, disassemble or hack any of our Chatrooms,
     services,  Chatrooms to defeat or overcome any of our encryption technologies
     or security measures or data transmitted, processed or stored by us, or
     for any reason at all not permitted under this Agreement or by Law;</span></li>
 <li><span>sell,
     transfer or try to sell or transfer an account with us or any part of an
     account including any virtual currency or virtual goods;</span></li>
 <li><span>disrupt the
     normal flow of a  Chatroom or otherwise act in a manner that is likely to
     negatively affect other users' ability to compete fairly when useing our  Chatrooms
     or engaging in real time exchanges;</span></li>
 <li><span>disobey any
     requirements or regulations of any network connected to our Website;</span></li>
 <li><span>use our
     Website to cheat or design or assist in cheating (for example, by using
     automated means or third party software to use), or to otherwise
     circumvent technological measures designed to control access to, or
     elements of, our Websites, or to do anything else that a reasonable person
     is likely to believe is not within the spirit of fair use or these Terms
     of Use; or</span></li>
 <li><span>use our
     Website in any other way not permitted by these Terms of Use.</span></li>
 <li><span>If you are
     concerned that someone else is not complying with any part of these Terms
     of Use, please contact us here:&nbsp;support@onylchat.io</span></li>
 <li><span>In
     particular, and without limiting the application of the present paragraph
     you must not make available any cheats or technological measures designed
     to control access to, or elements of, our Website, including providing
     access to any Virtual Currency and/or Virtual Goods, whether on a free of
     charge basis or otherwise.</span></li>
</ul>

<p><span>We reserve the
rights to terminate your account and revoke your rights and access without any
refunds.</span></p>

<p><span>By agreeing to
the terms of service of our website, you acknowledge and consent that we may
collect, use, and disclose the information and data you provide to us in
accordance with our privacy policy. This includes, but is not limited to, your
name, email address, pictures of YOU the USER and any other information you
provide when creating an account or submitting a form on our website. We may
use this information to improve our services,
and send you marketing and promotional materials. We may also share your
information with third-party service providers who assist us in providing and
improving our services. By submitting your information to us, you agree to our
collection, use, and disclosure of your information as described in this
policy. If you do not agree to these terms, please do not use our website or
submit any personal information to us.</span></p>

<p><span>&nbsp;</span></p>

<p><span>You agree that
we shall have the right to determine in our sole and unfettered discretion,
what action shall be taken in the event of any discovered or reported violation
of the Terms of Use contained here, including termination of the account and
revoke your rights and access without any refunds.</span></p>

<p><b><span>4.
Intellectual Property</span></b></p>

<p><span>The content contained
on the Website (with the exception of User Submissions), including but not
limited to the text, software, scripts, graphics, music, videos, photos,
sounds, interactive features and trademarks, service marks and logos contained
therein, are owned by and/or licensed to the Website, subject to copyright and
other intellectual property rights under the laws of the United States, Canada
and other laws and international conventions. Content on the Website is
provided to you AS IS for your information and personal use only and may not be
used, copied, reproduced, distributed, transmitted, broadcast, disuseed, sold,
licensed, or otherwise exploited for any other purposes whatsoever without the
prior written consent of the respective owners. We reserve all rights in and to
the Website and the content not expressly granted to you here. You agree to
not engage in the use, copying, or distribution of any of the content other
than expressly permitted here, including any commercial use, copying, and/or
distribution of User Submissions of third parties obtained through the Website.
If you download or print a copy of the content for personal use, you must
retain all copyright and other proprietary notices contained therein. You agree
not to disable, circumvent, or otherwise interfere with security related
features of the website or features that prevent or restrict use or copying of
any content or enforce limitations on use of the Website or the content
therein.</span></p>

<p><b><span>5. User
Submissions</span></b></p>

<p><span>The Website
allows/permits you to link to materials on the Website for personal,
non-commercial purposes only.</span></p>

<p><span>If you want Onylchat
to remove a User Submissions from the Website, please contact us at&nbsp;support@onylchat.io&nbsp;You
will need to provide us with specific information describing the location of
the User Submissions that you want us to remove and the reasons underlying your
request. We will take reasonable efforts to remove the User Submissions upon
receiving a sufficiently detailed request.</span></p>

<ul >
 <li><span>You own or
     retain the necessary licenses, rights, consents, and permissions to use
     and authorize us to use all trademarks, copyrights, trade secrets,
     patents, or other proprietary rights in and to any and all User
     Submissions to enable inclusion and use of the User Submissions in the
     manner contemplated by the Website and these Terms of Service; and</span></li>
 <li><span>You will not
     post, or allow anyone else to post, any material that depicts any person
     under the age of 18 years and you have inspected and are maintaining
     written documentation sufficient to confirm that all subjects of your
     submissions are, in fact, over the age of 18 years</span></li>
 <li><span>You have the
     written consent, release, and/or permission of each and every identifiable
     individual person in the User Submission to use their name or likeness in
     the User Submissions in the manner contemplated by the Website and this
     Agreement. For clarity, you retain all of your ownership rights in your
     User Submissions. By submitting the User Submissions to the Website, you
     hereby grant us a worldwide, non-exclusive, royalty-free, sublicensable
     and transferable license to use, reproduce, distribute, prepare derivative
     works of, publicly disuse, make available, communicate to the public, and
     publicly perform the User Submissions in connection with the Website. You
     also hereby grant each user of the Website a non-exclusive license to
     access your User Submissions through the Website, and to use, reproduce,
     distribute, prepare derivative works of, publicly disuse, make available,
     communicate to the public, and publicly perform such User Submissions as
     permitted through the functionality of the Website and under this
     Agreement. You also hereby waive any claim or cause of action against us
     for violation of any “moral rights” that you may possess in some
     jurisdictions.</span></li>
</ul>

<p><span>Your privacy is of utmost importance to us. We are committed to protecting your personal data in accordance with applicable data protection laws. User data will be handled responsibly, and we will not share your personal information without your explicit consent, except as required by law. For more information about how we handle user data, please refer to our detailed Privacy Policy.</span></p>

<p><span>We are committed to creating a responsible and safe environment on our Website. While you will encounter User data from diverse sources, we actively moderate and review content to ensure compliance with our policies and legal standards.</span></p>

<p><span>We have adopted
a policy, in compliance with the Digital Millennium Copyright Act&nbsp;<b><i>(“DMCA”)</i></b>,
to enable, at our sole discretion, the expeditious removal of infringing
material and the termination of repeat infringers’ accounts. If you have a good
faith belief that your copyright is being infringed by any content accessible
on or through the Website, please send a notice of claimed infringement,
including all of the information listed below, to our Designated Copyright
Agent at: support@onylchat.io</span></p>


<p><b><span>6. Account
Termination Policy</span></b></p>

<p><span>We reserve the
right to terminate a User's account for any reason or no reason, including
without limitation if we determine, in our sole discretion, that the User has
violated this Agreement, without prior notice. We also reserve the right to
render the Website temporarily or permanently inaccessible for any or no reason
in our sole discretion.</span></p>

<p><b><span>7.
Cancellation of Subscription</span></b></p>

<p><span>At any time,
and without cause, subscriptions to the service may be terminated by either: Onylchat,
the Website, or you upon notification of the other by electronic or
conventional mail, by chat, or by telephone. You are liable for charges
incurred until the date of the termination. You may cancel at any time by going
to “Profile” section and following the instructions to cancel your subscription and cancelling
Your subscription within PayPal, or by contacting our support department
through </span><a href="mailto:support@onylchat.io"><span>support@onylchat.io</span></a></p>

<p><span>To cancel your
subscription to our website, you must take the following steps: Cancel your
subscription on our website by following the instructions provided in your
account "Profile". Additionally, you can cancel your subscription through
PayPal by
following the instructions provided by PayPal. It is your responsibility to ensure that your
subscription is properly cancelled and that you will not be charged any
additional fees. We are not responsible for any fees charged by PayPal as a result
of your failure to cancel your subscription in both places. By subscribing to
our website, you acknowledge and agree to these cancellation policies and
procedures. If you have any questions or concerns about cancelling your
subscription, please contact our customer support team at: </span><a
href="mailto:support@onylchat.io"><span>support@onylchat.io</span></a></p>

<p><span>You may only
have one active subscription to our website at a time. While you may purchase
multiple subscriptions at the same time, you may only take advantage of the
benefits associated with one subscription at any given time. If you wish to
cancel a subscription, you must do so through our website by following the
instructions provided in your "Profile". Cancelling a subscription on
our website will terminate your access to the associated benefits. By subscribing to our website, you
agree to these terms and acknowledge that you are responsible for managing your
subscriptions and ensuring that you cancel them properly. If you have any
questions or concerns about your subscription or the cancellation process,
please contact our customer support team at: support@onylchat.io</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>8. User Privacy and Content Policy</span></b></p>

<p><span>We are committed to protecting the privacy and security of our users. Our platform implements stringent measures to ensure the confidentiality and integrity of all user data.</span></p>


<p><b><span>9. Fees</span></b></p>

<p><span>You acknowledge
that we reserve the right to charge for service and to change our fees from
time to time in our sole discretion. Furthermore, in the event that we
terminate your account, you shall not be entitled to the refund of any unused
credit, Gems, or  benefits.</span></p>

<p><span>We may charge
fees to access and acquire certain Chatrooms, Chatroom items or participate in  Chatroom
activities on the Website and may allow the purchase of in- Chatroom “currency”
(&quot;Gem/s&quot;) that may be applied to the purchase of  Chatrooms, in-
Chatroom items or activities. GEMS HAS NO MONETARY VALUE AND CANNOT BE REDEEMED FOR
CASH. YOU ARE FULLY LIABLE FOR ALL CHARGES TO YOUR ACCOUNT, INCLUDING ANY
UNAUTHORIZED CHARGES.</span></p>

<p><b><span>10. Our
Sales Policies</span></b></p>

<p><b><span>A. The store</span></b></p>

<p><span>You will then
be required to log into your account before proceeding to checkout. Prior to
the submission of your order, you will be required to submit billing and payment
information.</span></p>

<p><span>Items offered
for sale and/or redemption through the store are for personal use only. In the
event your order is canceled, or delayed please contact our support team at </span><a
href="mailto:support@onylchat.io"><span>support@onylchat.io</span></a><span> for getting help.</span></p>

<p><b><span>B. Use of
methods of Payment</span></b></p>

<p><span>If you have
elected to purchase any Subscriptions or Gem packages through the Website, you
hereby agree that we have the right to automatically charge your credit card or
debit your account for the applicable fees or charges. Value-Added Tax (VAT),
Sales Tax or other excise tax may be included in, or added to, your purchase
depending on your country, state, territory, city, or on other applicable local
regulations. Tax rates may vary accordingly.</span></p>

<p><span>Where and to
the extent required by applicable laws, you will be able to verify and modify
your order information before confirming your order. You hereby agree to
receive all notices and records in electronic form.</span></p>

<p><b><span>C. Mobile
Payments</span></b></p>

<ul >
 <li><span>Network
     charges may apply</span></li>
 <li><span>Please make
     sure that you are over 18 and have the bill payer's permission.</span></li>
 <li><span>For billing
     inquires contact: support@onylchat.io </span></li>
</ul>

<p><b><span>D. Refund Policy
</span></b></p>

<ul >
 <li><span>Onylchat offers a refund in accordance with the applicable terms and conditions.
</span></li>
 <li><span>Customers are entitled to a refund within a specified timeframe after the purchase or after the service has been provided (30 days from the date of purchase or service completion).</span></li>
 <li><span>Refunds are only granted under certain conditions, e.g. if the service has not been provided as described, technical problems have occurred or the service is unusable.</span></li>
 <li><span>Customers must submit a refund request in writing or via the means of communication provided by Onylchat (e-mail: support@onylchat.io or through PayPal).
</span></li>
 <li><span>The Provider reserves the right to review the refund request and verify that it complies with the specified conditions.</span></li>
 <li><span>Refunds are usually made in the same way as the original payment (chargeback to the customer's credit card, transfer to the bank account, or to the PayPal account of the customer).</span></li>
 <li><span>There may be certain instances where refunds will not be issued, such as misuse of the service or violation of the Terms of Service.</span></li>
 <li><span>The provider reserves the right to change or update these redemption conditions, whereby customers will be informed of such changes.</span></li>

</ul>

<p><b><span>E. monthly subscriptions
</span></b></p>

<ul >
 <li><span>Payment frequency: Payment is made monthly. The subscription is automatically renewed every 30 days from the date starting on which you (the customer) subscribed.
</span></li>
 <li><span>Payment methods: credit and debit cards are accepted, as well as PayPal.</span></li>
 <li><span>Cancellation policy: Subscription can be canceled at any time. Cancellation will take effect at the end of the current billing cycle. There are no refunds for partially used billing periods.</span></li>
 <li><span>Price changes: Changes to subscription fees will be communicated via email at least 30 days prior to taking effect.
</span></li>
 <li><span>Terms of delivery: the purchased services are provided immediately after successful payment.</span></li>

</ul>

<p><b><span>F. One-time transactions
</span></b></p>

<ul >
 <li><span>Payment date: Payment for one-time transactions is due immediately and in full at the time of purchase.
</span></li>
 <li><span>Payment methods: credit and debit cards are accepted, as well as PayPal.</span></li>
 <li><span>Terms of delivery: the purchased services are provided immediately after successful payment.</span></li>
 <li><span>Price guarantee: The price at the time of purchase is fixed and will not change afterwards.
</span></li>

</ul>



<p><b><span>11. Data
Processing</span></b></p>

<p><span>You acknowledge
and agree that the technical processing and transmission of the Website,
including your User Submissions, may involve transmissions over various
networks, foreign and domestic; and changes to conform and adapt to technical
requirements of connecting networks or devices. You further acknowledge and
agree that other data collected and maintained by the Website with regard to
its users may be disclosed in accordance with the Website’s Privacy Policy.</span></p>

<p><span>You acknowledge
and agree that the technical processing and transmission of the Website,
including your information and User Submissions, may involve transmissions over
various networks; and changes to conform and adapt to technical requirements of
connecting networks or devices. You further acknowledge and agree that other
data collected and maintained by the Website with regard to its users may be
disclosed in accordance with the Website’s Privacy Policy.</span></p>

<p><b><span>12. Warranty Disclaimer</span></b></p>

<p><span>YOU USE THE WEBSITE AT YOUR SOLE RISK. WE PROVIDE THE WEBSITE “AS IS” AND “AS AVAILABLE”. TO THE FULLEST EXTENT PERMITTED BY LAW, THE WEBSITE, ITS SITE OPERATOR, AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND RELATED TO THE WEBSITE AND GOODS OR SERVICES PURCHASED AND OBTAINED THROUGH THE WEBSITE, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM YOUR USE OF THE WEBSITE.</span></p>

<p><span>FURTHERMORE, THE WEBSITE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. USERS ARE ADVISED THAT ANY PERSONAL DATA SHARED OR TRANSMITTED THROUGH SUCH TRANSACTIONS IS DONE AT THEIR OWN RISK. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</span></p>

<p><span>NO INFORMATION
OBTAINED BY YOU FROM US OR THROUGH THE WEBSITE SHALL CREATE ANY WARRANTY NOT
EXPRESSLY STATED IN THESE TERMS.</span></p>

<p><span>The foregoing
shall apply to the fullest extent permitted by law in the applicable
jurisdiction. You specifically acknowledge that we shall not be liable for User
Submissions or the defamatory, offensive, or illegal conduct of any third party
and that the risk of harm or damage from the foregoing rests entirely with you.</span></p>

<p><b><span>13. Limitation of Liability</span></b></p>

<p><span>TO THE FULL EXTENT PERMITTED BY LAW, WE DISCLAIM ALL LIABILITY FOR ANY LOSS OR DAMAGE ARISING FROM THE USE OF OUR WEBSITE. THIS INCLUDES:</span></p>

<ul>
 <li><span>ANY CHANGES TO, OR TEMPORARY OR PERMANENT SUSPENSION OF, THE WEBSITE (IN WHOLE OR PART) WITHOUT NOTICE;</span></li>
 <li><span>TERMINATION OF YOUR ACCESS TO THE WEBSITE WITHOUT NOTICE FOR ANY REASON;</span></li>
 <li><span>THE ACCURACY, RELIABILITY, AND USEFULNESS OF ANY INFORMATION PROVIDED THROUGH THE WEBSITE;</span></li>
 <li><span>UNAUTHORIZED ACCESS TO, OR ALTERATION OF YOUR TRANSMISSIONS, DATA, OR PERSONAL INFORMATION;</span></li>
 <li><span>ANY CONSEQUENCES OF YOUR INTERACTIONS WITH THIRD-PARTY PROVIDERS OR OTHER USERS OF THE WEBSITE;</span></li>
 <li><span>THE DELETION, CORRUPTION, OR FAILURE TO STORE ANY CONTENT OR OTHER DATA MAINTAINED OR TRANSMITTED BY OUR SERVICES;</span></li>
 <li><span>YOUR FAILURE TO PROVIDE US WITH ACCURATE ACCOUNT INFORMATION;</span></li>
 <li><span>YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL.</span></li>
</ul>

<p><span>WE TAKE THE PRIVACY AND SECURITY OF USER DATA SERIOUSLY. HOWEVER, WE CANNOT GUARANTEE THAT UNAUTHORIZED THIRD PARTIES WILL NEVER BE ABLE TO DEFEAT OUR SECURITY MEASURES OR USE YOUR PERSONAL INFORMATION FOR IMPROPER PURPOSES. YOU ACKNOWLEDGE THAT YOU PROVIDE YOUR PERSONAL INFORMATION AT YOUR OWN RISK.</span></p>

<p><span>IN NO EVENT
SHALL WE BE LIABLE TO YOU FOR ANY INCIDENTAL, INDIRECT, PUNITIVE, STATUTORY,
EXEMPLARY, EXPECTATION, SPECIAL, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING
DAMAGES FOR LOSS OF PROFIT, LOSS OF GOODWILL, INTERRUPTION, LOSS OF BUSINESS
INFORMATION OR ANY OTHER FINANCIAL LOSS) IN ASSOCIATION WITH ANY CLAIM, OR ANY
LOSS, DAMAGE, ACTION, SUIT OR OTHER PROCEEDING RELATING TO OR ARISING UNDER OR
OUT OF THIS AGREEMENT, EVEN IF WE HAVE BEEN NOTIFIED OF THE POSSIBILITY OF SUCH
DAMAGES, WHETHER THE ACTION IS FOUNDED UPON CONTRACT, INFRINGEMENT OF
INTELLECTUAL PROPERTY RIGHTS, TORT, NEGLIGENCE OR OTHER GROUNDS. YOU AGREE NOT
TO FILE ANY LAWSUIT OR PROCEEDING INCONSISTENT WITH THE FOREGOING LIABILITY
LIMITATIONS.</span></p>

<p><span>SOME
JURISDICTIONS MAY NOT PERMIT CERTAIN LIABILITY LIMITATIONS. IF ANY COURT
DETERMINES THE LAW OF SUCH A JURISDICTION APPLIES, OUR LIABILITY SHALL BE LIMITED
TO THE GREATEST EXTENT PERMITTED BY LAW.</span></p>

<p><span>YOU RELEASE US
FROM ALL CLAIMS, DEMANDS, AND DAMAGES OF EVERY KIND AND NATURE, KNOWN AND
UNKNOWN, THAT ARE DESCRIBED IN THIS SECTION ON LIABILITY LIMITATIONS. YOU ALSO
WAIVE CALIFORNIA CIVIL CODE §1542 WHICH SAYS:</span></p>

<p><span>&quot;A GENERAL
RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT
TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF
KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH
THE DEBTOR.&quot; .</span></p>

<p><span>YOU ALSO WAIVE
ANY AND ALL BENEFITS AND RIGHTS THAT WOULD OTHERWISE ACCRUE TO YOU BY REASON OF
THE PROVISIONS OF ANY FEDERAL OR STATE STATUTE OR PRINCIPLE OF COMMON LAW OF
ANY STATE OF THE UNITED STATES, OR ANY POLITICAL ENTITY OR NATION, PROVINCE OR
LOCAL LAW OR REGULATION THAT MAY GOVERN THIS RELEASE, WHICH STATUTE,
REGULATION, LAW OR PRINCIPLE PROVIDES IN SUBSTANCE SOMETHING SIMILAR TO
CALIFORNIA CIVIL CODE § 1542. YOU AGREE NOT TO FILE ANY ACTION OR LAWSUIT
INCONSISTENT WITH THE FOREGOING RELEASE.</span></p>

<p><b><span>14.
Limitation on Time to File Claims</span></b></p>

<p><span>REGARDLESS OF
ANY STATUTE OR LAW TO THE CONTRARY, ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE
ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE WEBSITE MUST BE COMMENCED
WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF
ACTION OR CLAIM IS PERMANENTLY BARRED.</span></p>

<p><b><span>15. Your
Comments and Concerns</span></b></p>

<p><span>All notices of
copyright infringement claims should be sent to:
Support Email:&nbsp;support@onylchat.io</span></p>

<p><span>All other
feedback, comments, requests for technical support and other communications
relating to the Website should be directed to:
support@onylchat.io</span></p>

<p><b><span>16. Waiver
and Severability</span></b></p>

<p><span>No waiver by us
of any term or condition set forth in this Agreement shall be deemed a further
or continuing waiver of such term or condition or a waiver of any other term or
condition, and any failure by us to assert a right or provision under this
Agreement shall not constitute a waiver of such right or provision. All waivers
hereunder must be made in writing and signed by the waiving party.</span></p>

<p><span>If any
provision of this Agreement is found by a court of competent jurisdiction to be
invalid, the court should nevertheless endeavor to give effect to the parties'
intentions as reflected in the provision, and the other provisions of this Agreement
shall remain in full force and effect.</span></p>

<p><b><span>17.
Indemnity</span></b></p>

<p><span>You agree to
defend, indemnify and hold us harmless from and against any and all claims,
damages, obligations, losses, liabilities, costs or debt, and expenses
(including but not limited to attorney's fees) arising from:</span></p>

<ul >
 <li><span>Your use of
     and access to the Website and Chatrooms;</span></li>
 <li><span>Your
     violation of any term of this Agreement;</span></li>
 <li><span>Your
     violation of any third party right, including without limitation any
     copyright, property, or privacy right; or</span></li>
 <li><span>Any claim that
     one of your User Submissions caused damage to a third party.</span></li>
</ul>

<p><span>The Terms and
Conditions contained here and any rights and licenses granted hereunder, may
not be transferred or assigned by you, but may be assigned by it without
restriction.</span></p>

<p><b><span>18. Assignment</span></b></p>

<p><span>This Agreement
and any rights and licenses granted hereunder, may not be transferred or
assigned by you, but may be assigned by us without restriction.</span></p>

<p><b><span>19.
Miscellaneous</span></b></p>

<p><span>No party, nor
any of the parties’ respective attorneys, shall be deemed the drafter of this
agreement for purposes of interpreting any provision hereof in any judicial or
other proceeding that may arise between the parties.</span></p>

<p><span>Except as
otherwise expressly provided in this Agreement, there shall be no third-party
beneficiaries. For the purpose of clarity, our representatives, officers,
shareholders, subsidiaries, affiliates, parents, employees, licensors and
agents are intended third-party beneficiaries.</span></p>

<p><span>No agency,
partnership, joint venture, employee-employer or franchiser-franchisee relationship
is intended or created by this Agreement.</span></p>

<p><span>We may
terminate these Terms of Use for any or no reason at any time by notifying you
through a notice on the Website, by email, or by any other method of
communication. Any such termination will be without prejudice to our rights,
remedies, claims, or defenses hereunder. Upon termination of the Agreement, you
will no longer have a right to access your account and will not be refunded for
any Chatrooms, benefits or Gems.</span></p>
</div>
<Footer/>
</div>
    </div>
</div>
  );
};

export default TOS;