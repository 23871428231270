import React, {useContext}from "react";
import { useForm } from "./useForm";
import { useNavigate, useLocation } from "react-router-dom";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import { Timestamp , updateDoc, doc, setDoc} from 'firebase/firestore';
import {
  FormGroup,
  FormControl as Input,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import styles from "../../styles/MultiStepForm.module.scss";

import { v4 as uuid } from 'uuid';

const WaifuNameForm = () => {
  const { currentUser, userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectParam = queryParams.get("redirectPage");

  const { formState, handleChange, handleSubmit } = useForm(
    [{ key: "waifuName" }],
    onSubmit
  );

  const characterUID = userData && userData.customCharacter ? userData.customCharacter : uuid();

  async function onSubmit() {
    try {
      await setDoc(doc(db, "customCharacters", characterUID), {
        displayName: formState.waifuName,
      }, { merge: true });

      if (!userData.customCharacter) {
        await updateDoc(doc(db, "users", currentUser.uid), { customCharacter: characterUID });
      }

      if (redirectParam) {
        navigate(`/${redirectParam}`);
      } else {
        navigate("/EditYourWaifu"); // Default navigation if no parameter
      }
    } catch (error) {
      console.error("Error saving model name:", error);
    }
  }

  return (
    <div className={styles.wrapper}>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={6}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.titleContainer}>
                <h2 className={styles.title}>Waifu Name</h2>
                <span className={styles.description}>What's the name of your model?</span>
              </div>
              <FormGroup>
                <Input
                  type="text"
                  id="waifuName"
                  name="waifuName"
                  value={formState.waifuName || ""}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <button
                className={styles.button}
                type="submit"
                disabled={!formState.waifuName || formState.waifuName.trim() === ""}
              >
                Submit
              </button>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WaifuNameForm;