import { Modal, Button } from 'react-bootstrap';
import multipleGems from "../../img/multipleGems.png"
import { AuthContext } from "../../context/AuthContext";
import { useState, useContext } from 'react';
import { useNavigate, Link } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
  FieldValue                               
} from "firebase/firestore";
import {logEvent } from "../../firebase";
const SubscriptionModal = ({ show, handleClose, character }) => {
  const navigate = useNavigate();
    const { currentUser, userData } = useContext(AuthContext);
    const BuyOffer = async (productName, productPrice, type="product", productID="") => {
        logEvent(`Clicked to checkout through popup ${productName} ${productPrice}`)
          localStorage.setItem("targetPage", "shop");
          navigate("/shop")
    }
  return (
    <Modal show={show} onHide={handleClose} size="lg" contentClassName="subscription-window">
      <Modal.Header closeButton>
        <Modal.Title><h2>UNLIMITED ACCESS</h2></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <img
              src={character && character.photoURL.replace("verySmall","public")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <ul className="benefits-list">
                  <li>8000+ gems/month</li>
                  <li>Infinite Free Messages</li>
                  <li>Unlock All NSFW Content</li>
                  <li>Advanced AI language Model</li>
                  <li>Unlock "Your model"</li>
                  <li>Unlock Voice Messages</li>
                  <li>All Features Unlocked</li>
                </ul>
            <div className='subscriptions'>
    <div className="subscriptionItem">
    <div className='banner'><span className='bannerText'>Highest Value</span></div>
      <div className='product'><span>Onlychat Ultimate</span></div>
      <div className="price">4.99$/month</div>
      <div className="priceButton" onClick={() => {navigate("/shop"); handleClose();}}>
      Continue
      </div>
    </div>
    </div>
    <div className='learnMore'><a href="/shop" onClick={() => {handleClose();}}>Learn more</a></div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionModal;