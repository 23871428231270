import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { AuthContext } from "../../context/AuthContext";
import { useLocation } from 'react-router-dom';


import {
    collection,
    query,
    where,
    getDocs,
    setDoc,
    doc,
    updateDoc,
    serverTimestamp,
    getDoc,
    onSnapshot,
    FieldValue
} from "firebase/firestore";
import "../../styles/Checkout.scss"
import { db, logEvent } from "../../firebase";
import { useParams } from "react-router-dom";
import { getAnalytics } from "firebase/analytics";
import { message, Collapse } from "antd";
import * as Sentry from "@sentry/react";
const { Panel } = Collapse;

const Checkout = () => {
    const location = useLocation();
    const [showFeedback, setShowFeedback] = useState(false);
    const { productName, productPrice, type, productID } = useParams();
    const { currentUser, userData } = useContext(AuthContext);
    const UID = useRef(null);
    const navigate = useNavigate()
    useEffect(() => {
        if (currentUser && currentUser.uid !== undefined) {
            console.log('aksjdhkjasdaskd', currentUser)
            const uid = currentUser.uid;
            console.log(uid);
            UID.current = uid;
        }
    }, [currentUser]);
    // Function to log PayPal button click
    const logPayPalButtonClick = () => {
        console.log("paypal button click")
        logEvent('paypal_click_' + productName, {
            product_name: productName,
            product_id: productID,
        });
    };
    // creates a paypal order
    const createOrder = (data, actions) => {
        console.log("uid: ", UID);
        return actions.order
            .create({
                intent: "CAPTURE",
                purchase_units: [
                    {
                        description: productName,
                        amount: {
                            value: productPrice,
                            currency_code: "USD",
                        },
                        custom_id: UID.current,
                    },
                ],
                redirect_urls: {
                    return_url: 'https://oniichat.com/shop'
                },
                // not needed if a shipping address is actually needed
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            })
    };
    // set up the recurring transaction
    const createSubscription = (data, actions) => {
        // replace with your subscription plan id
        // https://developer.paypal.com/docs/subscriptions/#link-createplan

        return actions.subscription.create({
            intent: "subscription",
            name: productName,
            plan_id: productID,
            custom_id: UID.current
        });
    }
    // check Approval
    const onApprove = (data, actions) => {
        console.log("approved")

        return actions.order.capture().then(function (details) {
            const { payer } = details;
            console.log(details)
            localStorage.setItem("paymentProcessing", "true")
            // log purchase event
            logEvent('purchase', {
                value: productPrice,
                currency: 'USD',
                transaction_id: details.id,
                product_name: productName,
                product_id: productID,
                userId: UID.current,
                type: type, // 'subscription' or otherwise
            });
            window.trackExoClickConversion('purchaseGems',productPrice);
            const searchParams = new URLSearchParams(location.search);
            let source = searchParams.get('source')
            let subid = searchParams.get('subid')
            if (source === 'clickaine') {
                fetch(`https://keitaro.oniichat.com/f6767cf/postback?subid=${subid}&status=sale&from=clickaine&payout=${productPrice}`)
            }
            if (source === 'exoclick') {
                fetch(`https://keitaro.oniichat.com/f6767cf/postback?subid=${subid}&status=sale&from=exoclick&payout=${productPrice}`)
            }
            if (source === 'clickadu') {
                fetch(`https://keitaro.oniichat.com/f6767cf/postback?subid=${subid}&status=sale&from=clickadu&payout=${productPrice}`)
            }
            if (source === 'reddit') {
                fetch(`https://keitaro.oniichat.com/f6767cf/postback?subid=${subid}&status=sale&from=reddit&payout=${productPrice}`)
            }
            if (source === 'twitter') {
                fetch(`https://keitaro.oniichat.com/f6767cf/postback?subid=${subid}&status=sale&from=twitter&payout=${productPrice}`)
            }
            alert("Thanks for purchasing!")
            navigate("/shop")
        });
    };
    const payWithPayabl = async () => {

        let obj = {
            email: currentUser?.email,
            amount: productPrice,
            gems: productName,
            uid: currentUser?.uid
        }

        const searchParams = new URLSearchParams(location.search);
            let source = searchParams.get('source')
            let subid = searchParams.get('subid')

            if(source && subid){
                obj['source'] = source
                obj['subid'] = subid
            }

        const res = await fetch('https://onlychat-40d461c21104.herokuapp.com/api/pay-with-payabl', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(obj)
        });

        let data = await res?.json()

        if (data?.data) {
            console.log('ajksdkdhkjsad', currentUser, productPrice, data?.data)

            window.open(data?.data)
        }

    }
    const cancelSubscription = async () => {

        if (!currentUser.isAnonymous) {
            throw new Error("You must be logged in to cancel your subscription");
        }
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        const userData = userDoc.data();

        if (!userData || userData.subscription === "None") {
            throw new Error("You don't have an active subscription");
        }

        const data = {
            subscription_id: userData.subscription_id,
            uid: currentUser.uid
        };

        const res = await fetch('https://onlychat-40d461c21104.herokuapp.com/api/cancelsubscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!res.ok) {
            const error = await res.text();
            throw new Error(error);
        }
        console.log("response", res)
    };
    const onApproveSubscription = async (data, actions) => {

        console.log("approved")
        console.log(data)
        return actions.subscription.get().then(async function (details) {
            console.log(details)
            // User can upgrade to new plan
            // Check if user has an active subscription before trying to cancel it
            if (userData.subscription !== 'None') {
                // Cancel current subscription first
                try {
                    await cancelSubscription();
                } catch (error) {
                    console.error("Error cancelling subscription: ", error);
                    return;
                }
            }

            const searchParams = new URLSearchParams(location.search);
            let source = searchParams.get('source')
            let subid = searchParams.get('subid')
            if (source === 'clickaine') {
                fetch(`https://keitaro.oniichat.com/f6767cf/postback?subid=${subid}&status=sale&from=clickaine&payout=${productPrice}`)
            }
            if (source === 'exoclick') {
                fetch(`https://keitaro.oniichat.com/f6767cf/postback?subid=${subid}&status=sale&from=exoclick&payout=${productPrice}`)
            }
            if (source === 'clickadu') {
                fetch(`https://keitaro.oniichat.com/f6767cf/postback?subid=${subid}&status=sale&from=clickadu&payout=${productPrice}`)
            }
            if (source === 'reddit') {
                fetch(`https://keitaro.oniichat.com/f6767cf/postback?subid=${subid}&status=sale&from=reddit&payout=${productPrice}`)
            }
            if (source === 'twitter') {
                fetch(`https://keitaro.oniichat.com/f6767cf/postback?subid=${subid}&status=sale&from=twitter&payout=${productPrice}`)
            }
            logEvent('purchase', {
                value: productPrice,
                currency: 'USD',
                transaction_id: details.id,
                product_name: productName,
                product_id: productID,
                userId: UID.current,
                type: type, // 'subscription' or otherwise
            });
            window.trackExoClickConversion('purchaseGems',productPrice);
            localStorage.setItem("paymentProcessing", "true")
            alert("Thank you for your subscription! It may take up to 2 minutes for the subscription to be activated");
            navigate("/shop")
        })
    };
    const onError = (err) => {
        console.log(err);
        let errMessage = "An error occurred with your payment.";

        if (err.intent === 'CAPTURE' && err.message.includes('insufficient funds')) {
            message = "You have insufficient funds for this transaction.";
        }

        message.error(errMessage)
        Sentry.captureException(errMessage);
        // log error event
        logEvent('checkout_error', {
            error_message: err.message,
            error_name: err.name,
            product_name: productName,
            product_id: productID,
        });
    };
    const FeedbackForm = () => {
        const [feedback, setFeedback] = useState("");

        const submitFeedback = async () => {
            if (feedback.trim()) {
                const feedbackCollection = collection(db, "feedbacks");
                await setDoc(doc(feedbackCollection), {
                    text: feedback,
                    timestamp: serverTimestamp(),
                    user_id: UID.current
                });
                message.success("Feedback submitted. Thank you!");
                setFeedback(""); // Reset the textarea after submission
            } else {
                message.error("Please enter your feedback before submitting.");
            }
        };

        return (
            <div className="feedbackForm">
                <textarea
                    style={{ '::placeholder': { color: '#999' } }}
                    placeholder="Share your feedback with us..."
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                ></textarea>
                <button onClick={submitFeedback} className="button">Submit Feedback</button>
            </div>
        );
    };
    //live ATSozBY8MeQAaCrpz92R5wSOBfS2Jps2yBYwK3BRtbEA4d-SYvmTdO2Qt9mNYzIKqn6nQgI4rdnD5jUq sandbox AcSqWxw_8SgRS_KcFyj4bRPKrYoAvc_hJJE5k9-YRWJQSKUBpezAmAdIHOiHvimuF7F19cq1lxcH7vpg
    return (
        <div className="mainWrapper">
            <PayPalScriptProvider
                options={{
                    "client-id": "AdxWru7Bde9b6OrF95N1ZFIif9nIRVpuMgwChpnZBb6ZQ62DNy-AgIq0A70t82Ge42YbtVd27O06FnD4",
                    vault: true,
                    ...(type === "subscription"
                        ? { intent: "subscription" }
                        : { intent: "capture" }),
                }}
            >
                <div className="checkoutWrapper">
                    {/* Buy Anonymously Section */}
                    <div className="buy-anonymously-section">
                        <h2><i className="fas fa-user-secret"></i> Buy Anonymouslys</h2>
                        <p>Enjoy our services without revealing your identity. Your privacy matters to us. Charges will also appear anonymously on your billing statement.</p>
                    </div>
                    <div className="desc">
                        <div className="title">{type === "subscription" ? (<div>Subscribe to {productName}</div>) : (<div>Buy {productName}</div>)}</div>
                        <div className="price">{productPrice}$</div>
                    </div>
                    <div className="wrapper">
                        <PayPalButtons
                            onClick={logPayPalButtonClick}
                            style={{ layout: "vertical" }}
                            onError={onError}
                            {...(type === "subscription"
                                ? { createSubscription }
                                : { createOrder })}
                            {...(type !== "subscription" ? { fundingSource: "paypal" } : {})}
                            {...(type === "subscription"
                                ? { onApprove: onApproveSubscription }
                                : { onApprove: onApprove })}
                        />
                    </div>
                    {type !== "subscription" && (
                        <div className="wrapper">
                            <button className="payWithCreditCard" onClick={() => payWithPayabl()}>
                                <i className="fas fa-credit-card"></i> Pay with Credit Card
                            </button>
                        </div>
                    )}

                    <button className="showFeedback" onClick={() => setShowFeedback(!showFeedback)}>
                        {showFeedback ? "Hide Feedback" : "Provide Feedback"}
                    </button>
                    {showFeedback && <FeedbackForm />}
                </div>
            </PayPalScriptProvider>
        </div>
    );
}

export default Checkout;