import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import '../styles/FilterBar.scss';
import thumbUpIcon from '../img/Daumen.png';
import animeIcon from '../img/Anime.png'; // Corrected file path
import realisticIcon from '../img/Camera.png'; // Corrected file path
import animeGameIcon from '../img/Gaming.png'; // Corrected file path
import maleIcon from '../img/Male.png'; // Corrected file path
import specialIcon from '../img/Sparkles.png'; // Corrected file path
import privateVideoWaifusIcon from '../img/Video.png'; // Corrected file path
import newIcon from '../img/New.png'; // Corrected file path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

// Array of all categories
const allCategories = [
  { name: 'Recommended', emphasized: true },
  { name: 'Curvy', premium1: true },
  { name: 'Thick', premium1: true },
  { name: 'Mature', premium1: true },
  { name: 'Foreigner', premium1: true },
  { name: 'Cosplayer', premium1: true },
  { name: 'Favorites', emphasized: true },
];

const FilterBar = ({ handleSelectCategory, selectedCategory }) => {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser.isAnonymous) {
      // Check if 'Favorites' already exists in allCategories
      const hasFavorites = allCategories.some(category => category.name === 'Favorites');

      // Push 'Favorites' only if it doesn't exist
      if (!hasFavorites) {
        allCategories.push({ name: 'Favorites', emphasized1: true });
      }
    }
  }, [currentUser]);

  // Define the number of categories to show when not expanded
  const initialCategoriesCount = 6;

  const location = useLocation();
  const navigate = useNavigate();

  // Function to get the expanded state from URL
  const getExpandedFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('expanded') === 'true';
  };

  const [isExpanded, setIsExpanded] = useState(getExpandedFromURL());

  // Update URL when isExpanded changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (isExpanded) {
      searchParams.set('expanded', 'true');
    } else {
      searchParams.delete('expanded');
    }
    navigate({ search: searchParams.toString() }, { replace: true });
  }, [isExpanded, navigate]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Function to render a category button
  const renderCategoryButton = (category) => (
    <button
      key={category.name}
      className={`
        ${selectedCategory.toLowerCase() === category.name.toLowerCase() ? 'active' : ''} 
        filterButton 
        ${category.premium ? 'premium' : ''}
        ${category.premium1 ? 'premium1' : ''} 
        ${category.emphasized ? 'emphasized' : ''} 
        ${category.emphasized1 ? 'emphasized1' : ''} 
        ${category.emphasized2 ? 'emphasized2' : ''} 
        ${category.emphasized3 ? 'emphasized3' : ''}
      `}
      onClick={() => handleSelectCategory(category.name.toLowerCase())}
      aria-pressed={selectedCategory.toLowerCase() === category.name.toLowerCase()}
    >
      {category.name}
      {category.premium && <span className="premium-tag">Premium</span>}
    </button>
  );

  return (
    <div className={`filterBtnContainer ${isExpanded ? 'expanded' : ''}`}>
      {allCategories.slice(0, allCategories.length).map(renderCategoryButton)}

      {/* {allCategories.length > initialCategoriesCount && (
        <button className="expandButton" onClick={toggleExpand}>
          {isExpanded ? 'Collapse' : 'Expand All'}
          <FontAwesomeIcon 
            className="expandIcon" 
            style={{ margin: '10px' }} 
            icon={isExpanded ? faChevronUp : faChevronDown} 
          />
        </button> 
      )} */}
    </div>
  );
};

FilterBar.propTypes = {
  handleSelectCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
};

export default FilterBar;