import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import '../styles/YourWaifu.scss';
import MainNav from '../components/Common/MainNav';
import { AuthContext } from '../context/AuthContext';
import { ChatContext } from "../context/ChatContext";
import { useNavigate,  Redirect } from "react-router-dom";
import { getFirestore, doc, setDoc, updateDoc, getDoc, arrayUnion, getDocs, writeBatch, collection, where, query} from "firebase/firestore"
import { logEvent, db, AuthErrors } from "../firebase";
import SubscriptionModal from '../components/Chat/SubscriptionModal';
const YourWaifu = () => {
    const navigate = useNavigate()
    const {currentUser, userData} = useContext(AuthContext)
    const { displayType, setDisplayType, dispatch, data, setShowReward, showReward  } = useContext(ChatContext);
    const [waifuImage, setWaifuImage] = useState(null)
    const [customCharacter, setCustomCharacter] = useState(null)
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
    useEffect(() => {
        const fetchCustomCharacter = async () => {
            if (userData && userData.customCharacter) {
                try {
                    // Using await to handle the asynchronous function
                    const customCharacterDoc = await getDoc(doc(db, "customCharacters", userData.customCharacter));
                    console.log("====>", customCharacterDoc.data())
                    // Check if the document exists and has the expected structure
                    if (customCharacterDoc.exists && customCharacterDoc.data()?.imageURL) {
                        setCustomCharacter(customCharacterDoc.data())
                        setWaifuImage(customCharacterDoc.data().imageURL);
                        console.log("set", customCharacterDoc.data().imageURL)
                    } else {
                        console.warn("Document does not exist or has unexpected structure");
                    }

                    console.log(userData.customCharacter);
                } catch (error) {
                    console.error("Error fetching custom character:", error);
                }
            }
        };

        fetchCustomCharacter(); // Call the async function

    }, [userData]); // db added to the dependency array if it's expected to change
    const handleStartChatting = async () => {
        try {
            if(userData.subscription === "None"){
                setShowSubscriptionModal(true)
                return
              }
            const customCharacterDoc = await getDoc(doc(db, "customCharacters", userData.customCharacter));
            const customCharacter = customCharacterDoc.data()
            console.log("custom character", customCharacter, currentUser)
              // Check whether the group (chats in Firestore) exists, and create it if it doesn't
              const combinedId =
                currentUser.uid > userData.customCharacter
                  ? currentUser.uid + userData.customCharacter
                  : userData.customCharacter + currentUser.uid;
                  const userDocRef = doc(db, "users", currentUser.uid);
        
                  if (!userData.chats || !userData.chats.includes(combinedId)) {
                    await updateDoc(userDocRef, {
                      chats: arrayUnion(combinedId)
                    });
                  }
              const chatDocRef = doc(db, "chats", combinedId);
              console.log(combinedId);
              const chatDocSnapshot = await getDoc(chatDocRef);
              if (!chatDocSnapshot.exists() || chatDocSnapshot.data().messages === null) {
                await setDoc(chatDocRef, { messages: [], id: combinedId});
              }
              console.log("combinedId", combinedId)
            // Dispatch the character to the Redux store and navigate to the chat page
          dispatch({ type: "CHANGE_USER", payload: customCharacter });
          navigate(`/chat?char=${userData.customCharacter}&custom=${true}`)
          // Navigate the user to the chat page or whatever action you want to perform next
        } catch (error) {
          console.error('Error updating custom character traits:', error);
          // Handle the error appropriately, e.g., show an error message to the user.
        }
      };
    return (
        <div className='yourWaifu'>
        <MainNav />
        <div className="launcher-body">
            <div className="launcher-item">
                {userData && (
                    <>
                {/* Display model image if available */}
                {customCharacter?.character_persona ? (
                            <button className="btn-13 btn-13a icon-button" onClick={handleStartChatting}>
                                <img className="waifu-image" src={waifuImage} alt="Your model" />
                                <button className="startChatButton">Chat with your model</button>
                            </button>
                        ) : null}

                <Link to={userData && userData.customCharacter &&  userData.customCharacter ? ("/customProfilePage") :("/WaifuName")}>
                    <button className="btn-13 btn-13b icon-button">
                        <span className="btn-label">{customCharacter?.character_persona ? ("Edit Your model") : "Create Your model"}</span>
                    </button>
                </Link>
                </>
                )}
            </div>
        </div>
    </div>
    );
}

export default YourWaifu;