import React from 'react';

const LoaderModal = () => {
  return (
    <div className="loaderModal">
      <div className="modal-content">
      <div class="spin"></div>
        <h2>Processing your payment...</h2>
        <p>Your transaction is in progress. Please do not refresh or leave this page.</p>
        <p>For any issues, please contact us at <a href="mailto:support@onlychat.io">support@onlychat.io</a></p>
      </div>
    </div>
  );
}

export default LoaderModal;
