import React, {useState} from 'react';
import '../styles/SubscriptionPanel.scss'; // Import the SCSS


export const getPlanPrice = (planName) => {
    // Your logic to get the price of a plan based on its name
    // For example:
    switch(planName) {
      case "Basic Plan": return "4.99$";
      case "Plus Plan": return "7.99$";
      case "Ultimate Plan": return "9.99$";
      case "Annual Plan": return "4.99$";
      default: return "0.00$"; // No subscription
    }
  };
  // Function to get features for the Annual Plan
export const getFeaturesForAnnualPlan = () => {
  return {
    features: [
      // List features specific to the Annual Plan
      { text: "Unlimited gems for a year", available: true },
      { text: "Priority customer support", available: true },
      // ...other features...
    ],
    saleValue: "Best ",
    isBestValue: true,
    isAnnual: true
  };
};
  export const getFeaturesBySubscription = (subscription) => {
    let features = [];
    let saleValue = null;
    let isBestValue = false;
  
    switch (subscription) {
      case "Basic Plan":
        features = [
          { text: "1500 gems/month", available: true },
          { text: "Unlock all images, including NSFW content, for free.", available: true },
          { text: "Unlock blurred NSFW messages for free.", available: true }
        ];
        saleValue = "5x";
        break;
      case "Plus Plan":
        features = [
          { text: "4,200 gems/month", available: true },
          { text: "Unlock all images, including NSFW content, for free.", available: true },
          { text: "Unlock blurred NSFW messages for free.", available: true },
          { text: "20 Your model Generations", available: true }
        ];
        saleValue = "8x";
        break;
      case "Ultimate Plan":
        features = [
          { text: "8,250 gems/month", available: true },
          { text: "Unlock all images, including NSFW content, for free.", available: true },
          { text: "Unlock blurred NSFW messages for free.", available: true },
          { text: "Infinite free Messages", available: true },
          { text: "Experience lightning-fast loading times.", available: true },
          { text: "Advanced AI language Model", available: true }
        ];
        saleValue = "11x";
        isBestValue = true;
        break;
      default:
        features = [];
        saleValue = null;
        isBestValue = false;
    }
  
    return { features, saleValue, isBestValue };
  };
  export const SubscriptionPanel = ({ planName, planPrice, strikethroughPrice, currentPlan, onUpgradeClick, features, saleValue, isBestValue }) => {
    const [isLoading, setIsLoading] = useState(false);

    const getPlanValue = (planName) => {
      // Your logic to get the value of a plan based on its name
      switch(planName) {
        case "Basic Plan": return 1;
        case "Plus Plan": return 2;
        case "Ultimate Plan": return 3;
        default: return 0; // No subscription
      }
    };
    
    const isSubscribed = planName === currentPlan;
    const planValue = getPlanValue(planName);
    const currentPlanValue = getPlanValue(currentPlan);
    const canUpgrade = planValue > currentPlanValue;
    const canDowngrade = planValue < currentPlanValue;
    const noSubscriptionOwned = !currentPlan || currentPlan === '';
    
    const handleButtonClick = () => {
        if(canUpgrade || canDowngrade) {
          onUpgradeClick();
        }
    };
  
    let buttonText;
    if(isSubscribed) {
      buttonText = "Subscribed";
    } else if(canUpgrade) {
      buttonText = noSubscriptionOwned ? "Get Started" : "Upgrade";
    } else if(canDowngrade) {
      buttonText = "Downgrade";
    } else {
      buttonText = "Get Started";
    }
    
    return (
      <div className='subscription-panel'>
        <div className="card mb-5 mb-lg-0">
          <div className="card-body">
            {isBestValue && <div className='banner'><span className='bannerText'>BEST VALUE</span></div>}
            {saleValue && <div className='sale'>{saleValue} VALUE</div>}
            <h5 className="card-title text-muted text-uppercase text-center">{planName}</h5>
            <h6 className="card-price text-center">{planPrice}<span className="period">/month</span></h6>
            <ul className="fa-ul">
              {features.map((feature, index) => (
                <li key={index} className={feature.available ? 'check' : 'text-muted'}>{feature.text}</li>
              ))}
            </ul>
            <div className="d-grid">
              <button className="btn btn-primary text-uppercase" 
                onClick={handleButtonClick}
                disabled={isSubscribed || isLoading}>
                {isLoading ? 'Loading...' : buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  