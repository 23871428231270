import {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect
} from "react";
import { AuthContext } from "./AuthContext";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
  FieldValue                               
} from "firebase/firestore";
import { useLocation} from "react-router-dom";
export const ChatContext = createContext();
export const ChatContextProvider = ({ children }) => {
  const { currentUser, userData } = useContext(AuthContext);
  const [requesting, setRequesting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [showWarning, setShowWarning] = useState(false);
  const [showWallet, setShowWallet] = useState(false)
  const [showReward, setShowReward] = useState(false)
  const [targetPage, setTargetPage] = useState("selectchat")
  const [showVerification, setShowVerification] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [fullScreenImageSrc, setFullScreenImageSrc] = useState(null);
  const [characterData, setCharacterData] = useState(null)
  const [chatID, setChatID] = useState(null)
  const [selectedStory, setSelectedStory] = useState(null);
  const [showErr, setShowErr] = useState(false)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const characterUID = queryParams.get("char");
  const custom = queryParams.get("custom");
  const newChar = queryParams.get("new");
  const [chatData, setChatData] = useState(null);
  const [showSignUp, setShowSignUp] = useState(false)
  const [activeWindow, setActiveWindow] = useState(null);
  const [lastPrompt, setLastPrompt] = useState(null);
  const [displayType, setDisplayType]  = useState("Sign up now to access this feature and get 200 free gems as a welcome bonus!")
  const [showXpMessageAnimWithId, setShowXpMessageAnimWithId] = useState("")
  const [showNewLevelModal, setShowNewLevelModal] = useState(false)
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const [showRelationshipStatusModal, setShowRelationshipStatusModal] = useState(false)
  const [mediaPrice, setMediaPrice] = useState(20)
  const [voiceId, setVoiceId] = useState(() => {
    const storedVoice = localStorage.getItem('selectedVoice');
    return storedVoice ? JSON.parse(storedVoice).voiceId : "emma-watson";
  });
  const sessionId = currentUser ? currentUser.uid : localStorage.getItem('tempSessionId');

  const INITIAL_STATE = {
    chatId: "null",
    user: {},
  };
  useEffect(() => {
    let chatUnSub;
    if (chatID) {
      const docRef = doc(db, "chats", chatID);
      chatUnSub = onSnapshot(docRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setChatData(docSnapshot.data());
        } else {
          console.log("No such document!");
        }
      });
    }
    
    return () => {
      if(chatUnSub) chatUnSub();
    };
  }, [chatID, characterData]);
  useEffect(() => {
    if (!chatID) return;
    if (chatData && chatData.selectedStory) {
      setSelectedStory(chatData.selectedStory);
    } else {
      setSelectedStory(null);
    }
  }, [chatData]);
  useEffect(() => {
    if(!userData) return
    let unSub;
    const charactersCollection = newChar ? "newCharacters" : custom ? "customCharacters" : "characters";
    
    const replacePlaceholders = (data) => {
      if (data) {
        const characterName = data.displayName; // Extract characterName
        const keys = Object.keys(data);
        keys.forEach(key => {
          if (typeof data[key] === 'string') {
            data[key] = data[key].replace(/{{user}}|{{USER}}|<USER>/g, userData.FormData ?  userData.FormData.name : "Oniichan");
            data[key] = data[key].replace(/{{character}}|{{char}}|<CHARACTER>|<BOT>/g, characterName);
            data[key] = data[key].replace(/<START>/g, "");
          }
        });
      }
      return data;
    };
  
    if (characterUID) {
      unSub = onSnapshot(doc(db, charactersCollection, characterUID), (doc) => {
        console.log("======>", doc.data());
        const updatedData = replacePlaceholders(doc.data());
        setCharacterData(updatedData);
      });
    } else {
      setCharacterData(null);
    }
  
    return () => {
      if (unSub) unSub();
    };
  }, [characterUID, userData]);
  
  
  useEffect(() => {
    if (characterUID && sessionId) {
      setChatID(sessionId > characterUID ? sessionId + characterUID : characterUID + sessionId);
    } else {
      setChatID(null);
    }
  }, [characterUID, sessionId]);

  const chatReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          user: action.payload,
          chatId:
            sessionId > action.payload.uid
              ? sessionId + action.payload.uid
              : action.payload.uid + sessionId,
        };
      default:
        return state;
    }
  };


  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  return (
    <ChatContext.Provider value={{ data:state, dispatch, mediaPrice, setMediaPrice, showSubscriptionModal, setShowSubscriptionModal, showRelationshipStatusModal, setShowRelationshipStatusModal, lastPrompt, setLastPrompt, showNewLevelModal, setShowNewLevelModal, showXpMessageAnimWithId,setShowXpMessageAnimWithId ,fullScreenImageSrc, setFullScreenImageSrc, requesting, setRequesting, showModal, setShowModal, selectedImage, setSelectedImage, showWarning, setShowWarning, showWallet, setShowWallet, targetPage, setTargetPage, showVerification, setShowVerification, showErr, setShowErr, showMessageModal, setShowMessageModal, selectedMessage, setSelectedMessage, showReward, setShowReward, characterData, chatID, chatData, selectedStory, showSignUp,setShowSignUp, displayType, setDisplayType, activeWindow, setActiveWindow, voiceId, setVoiceId}}>
      {children}
    </ChatContext.Provider>
  );
};