// ExoClickUtil.js

import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const trackExoClickConversionFunction = httpsCallable(functions, 'trackExoClickConversion');

const trackExoClickConversion = async (eventType, conversionValue = null) => {
  const conversionId = localStorage.getItem('conversionTracking');
  if (!conversionId) {
    console.error('ExoClick Error: No conversion tracking ID found in localStorage');
    return;
  }

  try {
    const result = await trackExoClickConversionFunction({ 
      eventType, 
      conversionId, 
      conversionValue 
    });
    console.log(`ExoClick: ${result.data.message}`);
  } catch (error) {
    console.error(`ExoClick Error: ${error.message}`);
  }
};

// Expose the tracking function globally
window.trackExoClickConversion = trackExoClickConversion;