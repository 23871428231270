import { useState, useContext } from 'react';
import { db, storage } from "../../firebase";
import { Timestamp } from 'firebase/firestore';
import { v4 as uuid } from "uuid";
import { AuthContext } from "../../context/AuthContext";
import {
  arrayUnion,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  collection
} from "firebase/firestore";
import FeedbackContainer from './FeedbackContainer';

function Footer() {
  const [isImpressumOpen, setImpressumOpen] = useState(false);

  const toggleImpressum = () => setImpressumOpen(!isImpressumOpen);
  const ImpressumModal = ({ onClose }) => {
    return (
      <div className="impressum-modal">
      <div className="impressum-modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>&times;</span>
          <h2>Impressum</h2>
          <p><strong>Company Name: M.T.Kashi</strong> M.T.Kashi </p>
          <p><strong>Address:</strong> Mellingburgredder 17a, 22395 Hamburg, Germany</p>
          <p><strong>Email:</strong> <a href="mailto:support@onlychat.io">support@onlychat.io</a></p>
          <p><strong>Represented by:</strong> Fabian Kashi</p>
          <p><strong>Registration Number:</strong> 01117055</p>
          <p><strong>Registered at:</strong> Bezirksamt Wandsbek, Schloßgarten 9, 22041 Hamburg</p>
          <p><strong>VAT ID:</strong> DE347610385</p>
          <p>This information is provided in accordance with Germany's legal requirements.</p>
        </div>
      </div>
      </div>
    );
  }
  return (
    <footer className="footer">
    <div className="container">
      <div className="support-row">
        <h4 className="supportText">
          If you have any questions or problems please contact our 24/7 support team at 
          <a href="mailto:support@onlychat.io"> support@onlychat.io</a>
        </h4>
      </div>
      <div className="legal-row">
        <p className="feedback-text">
          ©2024 Onlychat - All Rights Reserved by M.T.Kashi 
          Onlychat - Trademarks owned by M.T.Kashi 
        </p>
      </div>
      <div className="links-row">
        <a href="/terms-of-use">Terms of Use</a> &amp; 
        <a href="/privacy-policy">Privacy Policy</a> &amp;
        <span className="impressum-link" onClick={toggleImpressum}>Impressum</span>
      </div>
    </div>
    {isImpressumOpen && <ImpressumModal onClose={toggleImpressum} />}
  </footer>
  );
}

export default Footer;