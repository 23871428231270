import React, { useRef, useState, useContext} from 'react';
import { Button, Modal, Form, Input, Upload, message, Progress } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { addDoc, collection } from '@firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage';
import { db, storage } from '../../firebase';
import { v4 as uuid } from 'uuid';
import { AuthContext } from '../../context/AuthContext';

const CharacterFormModal = ({ show, handleClose }) => {
  const {currentUser, userData} = useContext(AuthContext)
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewImage, setPreviewImage] = useState(null);
  const resetForm = () => {
    form.resetFields();
    setPreviewImage(null);
    setUploadProgress(0);
  };
  // Function to validate the image before upload
  const beforeUpload = (file) => {
    const isImage = file.type.indexOf('image/') === 0;
    if (!isImage) {
      message.error('You can only upload image files!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB!');
    }
    return isImage && isLt2M;
  };

  const handlePreview = async (file) => {
    if (!file || !file.originFileObj) {
      setPreviewImage(null);
    } else {
      const imageDataUrl = URL.createObjectURL(file.originFileObj);
      setPreviewImage(imageDataUrl);
    }
  };
  const [form] = Form.useForm();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    resetForm(); // Reset the form when the modal is closed without submitting
    setVisible(false);
  };

  const handleFinish = async (values) => {
    setConfirmLoading(true);
    try {
      const uniqueImageId = uuid();
      const imageRefStorage = ref(storage, `images/${uniqueImageId}`);
      const uploadTask = uploadBytesResumable(imageRefStorage, values.image.file.originFileObj);

      uploadTask.on('state_changed', (snapshot) => {
         // Calculate the upload progress
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      setUploadProgress(progress);
      }, (error) => {
        // Handle unsuccessful uploads
        console.error("Upload failed", error);
        message.error('Image upload failed. Please try again');
        setConfirmLoading(false);
      }, async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        const docData = {
          name: values.name,
          description: values.description,
          imageUrl: downloadURL,
          userName: userData.FormData.name,
          createdBy: currentUser.uid, // assumes currentUser object contains user's unique ID
          creationDate: new Date().toISOString(), // current date and time in ISO string format
          votes: { upvotes: 0, downvotes: 0 }
        };

        await addDoc(collection(db, 'votingCharacters'), docData);
        setConfirmLoading(false);
        setVisible(false);
        resetForm();
        message.success('Character added successfully');
        handleClose()
      });
    } catch (error) {
      console.error(error);
      message.error('Failed to add character. Please try again');
      setConfirmLoading(false);
    }
  };

  return (
    <Modal title="Post model" visible={show} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleClose}>
        <Form form={form} onFinish={handleFinish}>
          <Form.Item name="name" rules={[{ required: true, message: 'Please input the model name' }]}>
            <Input placeholder="Waifu Name" />
          </Form.Item>
          <Form.Item name="description" rules={[{ required: true, message: 'Please input the model description' }]}>
            <Input.TextArea placeholder="Waifu Description" />
          </Form.Item>
          <Form.Item name="image" valuePropName="file" rules={[{ required: true, message: 'Please upload an image' }]}>
        <Upload.Dragger
          name="file"
          maxCount={1}
          beforeUpload={beforeUpload}
          onChange={info => handlePreview(info.file)}
          onRemove={() => setPreviewImage(null)}
        >
          {previewImage && <img src={previewImage} alt="Preview" style={{ margin: '10px', height: '200px' }} />}
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Upload.Dragger>
        {uploadProgress > 0 && <Progress percent={uploadProgress} />}
      </Form.Item>
        </Form>
      </Modal>
  );
};

export default CharacterFormModal;
