import React, {useContext, useState, useEffect} from 'react';
import { AuthContext } from '../context/AuthContext.js';
import { signOut, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, GithubAuthProvider,reauthenticateWithPopup, linkWithPopup, EmailAuthProvider, fetchSignInMethodsForEmail, reauthenticateWithCredential, deleteUser} from "firebase/auth";
import { auth  } from "../firebase";
// Assuming you have the `SubscriptionPanel` component imported or defined elsewhere in your project
import {SubscriptionPanel, getPlanPrice, getFeaturesBySubscription} from '../components/SubscriptionPanel.jsx';
import styles from '../styles/FirstCancelWarning.module.css';
import { useNavigate, Link } from "react-router-dom";
import SecondCancelWarning from './SecondCancelWarning.jsx';
import { Spin } from 'antd';
const FirstCancelWarning = () => {
    const [saleValue, setSaleValue] = useState(0); // Replace with your sale value
    const [isBestValue, setIsBestValue] = useState(false); // Replace with your logic or variable
    const {userData} = useContext(AuthContext)
    const [proceed, setProceed] = useState(false)
    const [calculatedFeatures, setCalculatedFeatures] = useState(null)
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 70000);
      }, [proceed]);
    useEffect(() => {
        if(userData)
        {
        const { features, saleValue, isBestValue } = getFeaturesBySubscription(userData.subscription);
        setSaleValue(saleValue)
        setIsBestValue(isBestValue)
        setCalculatedFeatures(features)
        }
    }, [userData]);
    if (loading) {
        return (
            <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        );
      }
    return (
        <>
        {(userData && calculatedFeatures && !proceed) && (
      <div className={styles.container}>
        <h1 className={styles.heading}>WHY DO YOU WANT TO CANCEL?</h1>
  
        <h2 className={styles.subheading}>EXPLORE THE FULL POTENTIAL OF ONIICHAT</h2>
  
        <SubscriptionPanel 
          planName={userData.subscription}
          planPrice={getPlanPrice(userData.subscription)}
          currentPlan={userData.subscription}
          onUpgradeClick={null}
          features={calculatedFeatures}
          saleValue={saleValue}
          isBestValue={isBestValue}
        />

        <p className={styles.supportLink}>
          You need help? Contact our support: 
            support@onlychat.io
        </p>
  
        <button className="button" onClick={() => navigate("/shop")}>EXPLORE</button>
  
        <div className={styles.cancelLink}>
          <a onClick={() => {setProceed(true);setLoading(true)}}>PROCEED WITH CANCELLATION</a>
        </div>
      </div>)}

      {proceed && (
        <SecondCancelWarning/>
      )}
      </>
  );
}

export default FirstCancelWarning;