import React, { useCallback, useEffect, useState, useMemo, useContext } from "react";
import { collection, query, doc, getDoc, onSnapshot, orderBy, where, limit } from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase";
import Character from "../../components/Voting/Character";
import CharacterFormModal from '../../components/Voting/CharacterFormModal';
import CountdownComponent from '../../components/Voting/CountdownComponent';
import MainNav from "../../components/Common/MainNav"
import { Button, Spin, Skeleton } from 'antd';
import { v4 as uuid } from "uuid";
import WinnerCard from "../../components/Voting/Winnercard";
const VotingCharacters = () => {
  const [filter, setFilter] = useState('popular');
  const { currentUser, userData } = useContext(AuthContext);
  const [characters, setCharacters] = useState([]);
  const [sortedCharacters, setSortedCharacters] = useState([])
  const [loading, setLoading] = useState(true); // Set initial state to loading
  const [showCharacterForm, setShowCharacterForm] = useState(false);
  const [mySubmissionsOnly, setMySubmissionsOnly] = useState(false);
  const [error, setError] = useState(null);
  const [countdownEnded, setCountdownEnded] = useState(false);
  const [winner, setWinner] = useState(null);

  const filterOptions = {
    NEW: 'new',
    POPULAR: 'popular',
    TRENDING: 'trending',
    MY_SUBMISSIONS: 'mySubmissions',
  };
  
  useEffect(() => {
    // ... other code
    if (countdownEnded) {
      setLoading(true);
      if (characters && characters.length > 0) {
        const maxVotesCharacter = characters.reduce((max, character) => {
          const maxVotes = max?.votes?.upvotes - max?.votes?.downvotes;
          const characterVotes = character?.votes?.upvotes - character?.votes?.downvotes;

          return characterVotes > maxVotes ? character : max;
        }, characters[0]);

        if (maxVotesCharacter) {
          setWinner(maxVotesCharacter);
        }
        console.log("votes character", maxVotesCharacter)
      }
      setLoading(false);
    }
  }, [countdownEnded, characters]); // we added 'characters' to the dependency array as the effect depends on it too
  const fetchCharacters = useCallback(async () => {
    setError(null);
  
    console.log(userData); // Debugging userData.
  
    try {
      let charactersQuery = query(collection(db, 'votingCharacters'));
      console.log(mySubmissionsOnly)
  
      const unsubscribe = onSnapshot(charactersQuery, (charactersSnapshot) => {
        let charactersList = charactersSnapshot.docs.map(doc => {
          const data = doc.data();
          return { ...data, id: doc.id }; // Include the votes in the character data
        });
  
        setCharacters(charactersList);
        setLoading(false);
      }, (error) => {
        console.error(error);
        setLoading(false);
        setError(error.message);
      });
  
      return () => unsubscribe();
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(error.message);
    }
  }, [mySubmissionsOnly, userData]);
  

  useEffect(() => {
    fetchCharacters();
  }, [fetchCharacters]);

  const renderedCharacters = useMemo(() => {
    try {
      let filteredCharacters = [...characters];
  
      // Calculate trending score for each character
      filteredCharacters.forEach(character => {
        try {
          const hoursSincePosted = (Date.now() - new Date(character.creationDate).getTime()) / 1000 / 60 / 60; // Assuming creationDate is a timestamp in milliseconds
  
          const voteScore = character.votes.upvotes - character.votes.downvotes; // Calculate total vote score
  
          character.trendingScore = voteScore / (hoursSincePosted || 1); // Avoid division by 0
        } catch(error) {
          console.error("Error calculating trending score for a character:", error);
        }
      });
  
      switch (filter) {
        case filterOptions.NEW:
          filteredCharacters.sort((a, b) => {
            const aCreationDate = a.creationDate ? new Date(a.creationDate).getTime() : -Infinity;
            const bCreationDate = b.creationDate ? new Date(b.creationDate).getTime() : -Infinity;
            
            return bCreationDate - aCreationDate;
          });
          break;
        case filterOptions.POPULAR:
          filteredCharacters.sort((a, b) => (b.votes.upvotes - b.votes.downvotes) - (a.votes.upvotes - a.votes.downvotes));
          break;
        case filterOptions.TRENDING:
          filteredCharacters.sort((a, b) => b.trendingScore - a.trendingScore);
          break;
        case filterOptions.MY_SUBMISSIONS:
          filteredCharacters = filteredCharacters.filter(
            (character) => character.createdBy === userData?.uid // Assuming userData.uid is available and contains the current user's id
          );
          break;
        default:
          break;
      }
  
      const calculateRank = (characterId) => {
        let charactersSorted = characters.sort(
          (a, b) => (b.votes.upvotes - b.votes.downvotes) - (a.votes.upvotes - a.votes.downvotes)
        );
        const index = charactersSorted.findIndex((character) => character.id === characterId);
        return index !== -1 ? index : null;
      };
  
      if (filteredCharacters.length > 0) {
        console.log(filteredCharacters);
        return filteredCharacters.map((character) => (
          <Character
            key={character.id}
            character={character}
            rank={calculateRank(character.id)}
          />
        ));
      } else {
        return (
          <div style={{ justifyContent: "center", display: "flex", marginTop: "3rem", color: "white" }}>
            <p>No models found.</p>
          </div>
        );
      }
    } catch(error) {
      console.error("Error rendering characters:", error);
    }
  }, [characters, userData, filter]);

  return (
<div class="mainWrapper">
  <MainNav />
  {loading || !currentUser ? (
    <div style={{ justifyContent: "center", display: "flex", marginTop: "3rem" }}>
      <Spin size="large"/>
    </div>
  ) : !countdownEnded ? (
    <div class="contentWrapper">
      <h1 class="headline">The Countdown Is On</h1>
      <h2 class="subheadline">First placed model in TOP 🏆 will be added to the platform when the countdown ends!</h2>
      <CountdownComponent onEnd={() => setCountdownEnded(true)} />
      <button class="button addCharacterBtn" onClick={() => setShowCharacterForm(true)}>Post model</button>
      <div className="filterBtnContainer">
      <button class={`filterButton ${filter === filterOptions.POPULAR ? 'active' : ''}`} onClick={() => setFilter(filterOptions.POPULAR)}>TOP 🏆</button>
      <button class={`filterButton ${filter === filterOptions.NEW ? 'active' : ''}`} onClick={() => setFilter(filterOptions.NEW)}>Recent</button>
        <button class={`filterButton ${filter === filterOptions.TRENDING ? 'active' : ''}`} onClick={() => setFilter(filterOptions.TRENDING)}>Trending</button>
        <button class={`filterButton ${filter === filterOptions.MY_SUBMISSIONS ? 'active' : ''}`} onClick={() => setFilter(filterOptions.MY_SUBMISSIONS)}>My Submissions</button>
      </div>
      <CharacterFormModal show={showCharacterForm} handleClose={() => setShowCharacterForm(false)} />
      {error && <p>{error}</p>}
      <div>
        {loading ? (
          <div style={{ justifyContent: "center", display: "flex", marginTop: "3rem" }}>
            <Spin />
          </div>
        ) : characters.length === 0 ? (
          <div style={{ justifyContent: "center", display: "flex", marginTop: "3rem", color: "white" }}>
            <p>No models found.</p>
          </div>
        ) : (
          renderedCharacters
        )}
      </div>
    </div>
  ) : winner && (
    <div class="contentWrapper">
      <h1 class="headline">The Countdown Is Over</h1>
      <h2 class="subheadline">{winner.name} will be added to the platform very soon.</h2>
      <CountdownComponent onEnd={() => setCountdownEnded(true)} />
      <WinnerCard winner={winner} />
    </div>
  )}
</div>
  );
};

export default React.memo(VotingCharacters);