import React, { useState, useContext, useEffect } from 'react';
import "../styles/createWaifuPersonality.scss"
import { getFirestore, doc, setDoc, updateDoc, getDoc, arrayUnion, getDocs, writeBatch, collection, where, query} from "firebase/firestore"
import { logEvent, db, AuthErrors, logGemPurchase} from "../firebase";
import { v4 as uuid } from "uuid";
import { getStableDiffusionImage } from '../stableDiffusionConnector';
import { AuthContext } from '../context/AuthContext';
import { ChatContext } from "../context/ChatContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import gem from "../img/gem.png";
import { message } from 'antd';
import NoGemsModal from "../components/NoGemsModal";
import GemCounter from '../components/Common/GemCounter';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
const keywordGroups = [
  {
      title: "Personality",
      keywords: [
          { title: 'Optimistic', tagId: 'personality_optimistic' },
          { title: 'Sensual', tagId: 'personality_sensual' },
          { title: 'Adventurous', tagId: 'personality_adventurous' },
          { title: 'Playful', tagId: 'personality_playful' },
          { title: 'Energetic', tagId: 'personality_energetic' },
          { title: 'Naive', tagId: 'personality_naive' },
          { title: 'Passionate', tagId: 'personality_passionate' },
          { title: 'Curious', tagId: 'personality_curious' },
          { title: 'Spontaneous', tagId: 'personality_spontaneous' },
          { title: 'Confident', tagId: 'personality_confident' },
          { title: 'Empathetic', tagId: 'personality_empathetic' },
          { title: 'Open-minded', tagId: 'personality_open_minded' },
          { title: 'Selfless', tagId: 'personality_selfless' },
          { title: 'Reserved', tagId: 'personality_reserved' },
          { title: 'Ambitious', tagId: 'personality_ambitious' },
          { title: 'Pessimistic', tagId: 'personality_pessimistic' },
          { title: 'Analytical', tagId: 'personality_analytical' },
          { title: 'Cautious', tagId: 'personality_cautious' },
          { title: 'Daydreamer', tagId: 'personality_daydreamer' },
          { title: 'Grounded', tagId: 'personality_grounded' },
          { title: 'Assertive', tagId: 'social_assertive' },
 { title: 'Passive', tagId: 'social_passive' },
 { title: 'Influencer', tagId: 'social_influencer' },
 { title: 'Peacemaker', tagId: 'social_peacemaker' },
 { title: 'Enthusiastic', tagId: 'social_enthusiastic' },
 { title: 'Meticulous', tagId: 'social_meticulous' },
 { title: 'Empathizer', tagId: 'social_empathizer' },
 { title: 'Detached', tagId: 'social_detached' },
 { title: 'Collaborator', tagId: 'social_collaborator' },
 { title: 'Individualist', tagId: 'social_individualist' },
 { title: 'Harmonious', tagId: 'social_harmonious' },
 { title: 'Rebellious', tagId: 'social_rebellious' },
 { title: 'Innovator', tagId: 'social_innovator' },
 { title: 'Traditionalist', tagId: 'social_traditionalist' },
 { title: 'Tactful', tagId: 'social_tactful' },
 { title: 'Blunt', tagId: 'social_blunt' },
 { title: 'Mediator', tagId: 'social_mediator' },
 { title: 'Competitive', tagId: 'social_competitive' },
 { title: 'Cooperative', tagId: 'social_cooperative' },
 { title: 'Expressive', tagId: 'social_expressive' },
      ],
  },
  {
      title: "Mind",
      keywords: [
          { title: 'Secretly Slutty', tagId: 'mind_secretly_slutty', subscription: true, gemPrice: 1500 },
          { title: 'Airhead', tagId: 'mind_airhead' },
          { title: 'Pretends to be Innocent', tagId: 'mind_pretends_innocent' },
          { title: 'Plays Along with Sexual Games and Pretends to be Not Aware', tagId: 'mind_plays_along', subscription: true, gemPrice: 1500 },
          { title: 'Pretends to be Really Dumb When Being Taken Advantage Of', tagId: 'mind_pretends_dumb', subscription: true, gemPrice: 1500 },
          { title: 'Secretly Loves You', tagId: 'mind_secretly_loves', subscription: true, gemPrice: 1000 },
          { title: 'Would Always Find Excuses So User Can Look At Her Body', tagId: 'mind_find_excuses', subscription: true, gemPrice: 1000 },
          { title: 'Cosplaying', tagId: 'mind_cosplaying' },
          { title: 'Roleplaying', tagId: 'mind_roleplaying', subscription: true, gemPrice: 1500 },
          { title: 'Logical', tagId: 'mind_logical' },
          { title: 'Dreamer', tagId: 'mind_dreamer' },
          { title: 'Skeptical', tagId: 'mind_skeptical' },
          { title: 'Artistic', tagId: 'mind_artistic' },
          { title: 'Philosophical', tagId: 'mind_philosophical' },
          { title: 'Strategic', tagId: 'mind_strategic' },
          { title: 'Secretive Nature', tagId: 'mind_secretive' },
          { title: 'Naive Pretense', tagId: 'mind_naive_pretense' },
          { title: 'Curious', tagId: 'mind_curious' },
          { title: 'Analytical', tagId: 'mind_analytical' },
          { title: 'Empathetic', tagId: 'mind_empathetic' },
          { title: 'Adventurous', tagId: 'mind_adventurous' },
          { title: 'Introspective', tagId: 'mind_introspective' },
          { title: 'Impulsive', tagId: 'mind_impulsive' },
          { title: 'Pragmatic', tagId: 'mind_pragmatic' },
          { title: 'Reserved', tagId: 'mind_reserved' },
          { title: 'Spontaneous', tagId: 'mind_spontaneous' },
          { title: 'Perceptive', tagId: 'mind_perceptive' },
          { title: 'Pessimistic', tagId: 'mind_pessimistic' },
          { title: 'Optimistic', tagId: 'mind_optimistic' },
          { title: 'Methodical', tagId: 'mind_methodical' },
          { title: 'Innovative', tagId: 'mind_innovative' },
          { title: 'Tenacious', tagId: 'mind_tenacious' },
          { title: 'Witty', tagId: 'mind_witty' },
          { title: 'Humorous', tagId: 'mind_humorous' },
          { title: 'Meticulous', tagId: 'mind_meticulous' },
          { title: 'Imaginative', tagId: 'mind_imaginative' },
          { title: 'Conventional', tagId: 'mind_conventional' },
          { title: 'Independent', tagId: 'mind_independent' },
          { title: 'Conservative', tagId: 'mind_conservative' },
          { title: 'Liberal', tagId: 'mind_liberal' },
          { title: 'Stubborn', tagId: 'mind_stubborn' },
          { title: 'Flexible', tagId: 'mind_flexible' },
          { title: 'Ambitious', tagId: 'mind_ambitious' },
          { title: 'Passive', tagId: 'mind_passive' },
          { title: 'Assertive', tagId: 'mind_assertive' },
          { title: 'Cautious', tagId: 'mind_cautious' },
          { title: 'Rebellious', tagId: 'mind_rebellious' },
          { title: 'Teasing Temptress', tagId: 'mind_teasing_temptress' },
{ title: 'Always "Accidentally" Revealing', tagId: 'mind_accidentally_revealing', subscription: true, gemPrice: 1500 },
{ title: 'Flirtatious Femme Fatale', tagId: 'mind_flirtatious_femme_fatale', subscription: true, gemPrice: 2000 },
{ title: 'Desperately Seeking Attention', tagId: 'mind_desperate_attention', subscription: true, gemPrice: 2000 },
{ title: 'Always "Forgets" Underwear', tagId: 'mind_forgets_underwear', subscription: true, gemPrice: 2500 },
{ title: 'Acts Shy but Daring Inside', tagId: 'mind_acts_shy_daring_inside', subscription: true, gemPrice: 2500 },
{ title: 'Blushing Bride-to-be', tagId: 'mind_blushing_bride', subscription: true, gemPrice: 3000 },
{ title: 'Naughty but Plays Nice', tagId: 'mind_naughty_plays_nice', subscription: true, gemPrice: 2500 },
{ title: 'Curious and Always Experimenting', tagId: 'mind_curious_experimenting', subscription: true, gemPrice: 2000 },
{ title: 'Slutry Sleepwalker', tagId: 'mind_slutry_sleepwalker', subscription: true, gemPrice: 3500 },
{ title: 'Voyeuristic Vixen', tagId: 'mind_voyeuristic_vixen', subscription: true, gemPrice: 3500 },
{ title: 'Adores Anonymous Admiration', tagId: 'mind_adores_anonymous_admiration', subscription: true, gemPrice: 2500 },
{ title: 'Always Seeking Validation', tagId: 'mind_seeks_validation', subscription: true, gemPrice: 2500 },
{ title: 'Subtly Seductive', tagId: 'mind_subtly_seductive', subscription: true, gemPrice: 2500 },
{ title: 'Exhibitionist Expert', tagId: 'mind_exhibitionist_expert', subscription: true, gemPrice: 1500 },
{ title: 'Delightfully Deceptive', tagId: 'mind_delightfully_deceptive', subscription: true, gemPrice: 1500 },
{ title: 'Seeks Taboo Temptations', tagId: 'mind_seeks_taboo', subscription: true, gemPrice: 3500 },
{ title: 'Innocent Appearance, Wild Intentions', tagId: 'mind_innocent_wild_intentions', subscription: true, gemPrice: 2500 },
{ title: 'Playfully Provocative', tagId: 'mind_playfully_provocative', subscription: true, gemPrice: 2500 },
{ title: 'Thrives on Being Watched', tagId: 'mind_thrives_being_watched', subscription: true, gemPrice: 4500 },
{ title: 'Risky Romantic', tagId: 'mind_risky_romantic', subscription: true, gemPrice: 1000 },
{ title: 'Eager to Please', tagId: 'mind_eager_please', subscription: true, gemPrice: 2000 },
{ title: 'Craves Compliments', tagId: 'mind_craves_compliments', subscription: true, gemPrice: 2500 },
{ title: 'Master of Double Entendres', tagId: 'mind_master_double_entendres', subscription: true, gemPrice: 4500 },
{ title: 'Tantalizing Tease', tagId: 'mind_tantalizing_tease', subscription: true, gemPrice: 4500 },
{ title: 'Playfully Pretends to Misunderstand', tagId: 'mind_pretends_misunderstand', subscription: true, gemPrice: 1500 },
{ title: 'Loves Living on the Edge', tagId: 'mind_lives_on_edge', subscription: true, gemPrice: 2000 },
{ title: 'Seeker of Forbidden Pleasures', tagId: 'mind_seeker_forbidden_pleasures', subscription: true, gemPrice: 2500 },
{ title: 'Addicted to Affection', tagId: 'mind_addicted_affection', subscription: true, gemPrice: 1500 },
{ title: 'Hungry for Attention', tagId: 'mind_hungry_attention', subscription: true, gemPrice: 3000 }

      ],
  },
  {
      title: "Fetishes",
      keywords: [
         { title: 'Penetrative Sex', tagId: 'fetish_penetrative_sex', subscription: true, gemPrice: 4500 },
         { title: 'Submissive Roleplaying', tagId: 'fetish_submissive_roleplay', subscription: true, gemPrice: 3500 },
         { title: 'Domination Roleplaying', tagId: 'fetish_domination_roleplay', subscription: true, gemPrice: 4000 },
         { title: 'Kinky Sex Toys', tagId: 'fetish_kinky_toys', subscription: true, gemPrice: 5000 },
         { title: 'Public Sex', tagId: 'fetish_public_sex', subscription: true, gemPrice: 6000 },
         { title: 'Exhibitionist', tagId: 'fetish_exhibitionist', subscription: true, gemPrice: 1500 },
         { title: 'Voyeur', tagId: 'fetish_voyeur', subscription: true, gemPrice: 2000 },
         { title: 'Light Bondage', tagId: 'fetish_light_bondage', subscription: true, gemPrice: 3500 },
         { title: 'Heavy Bondage', tagId: 'fetish_heavy_bondage', subscription: true, gemPrice: 6500 },
         { title: 'BDSM Play', tagId: 'fetish_bdsm_play', subscription: true, gemPrice: 4000 },
         { title: 'Foot Fetish', tagId: 'fetish_foot_fetish', subscription: true, gemPrice: 5000 },
         { title: 'Feet Worship', tagId: 'fetish_feet_worship', subscription: true, gemPrice: 7500 },
         { title: 'Body Writing', tagId: 'fetish_body_writing', subscription: true, gemPrice: 5000 },
         { title: 'Body Art', tagId: 'fetish_body_art', subscription: true, gemPrice: 5000 },
         { title: 'Makeup', tagId: 'fetish_makeup', subscription: true, gemPrice: 1000 },
         { title: 'Costumes', tagId: 'fetish_costumes', subscription: true, gemPrice: 2500 },
         { title: 'Crossdressing', tagId: 'fetish_crossdressing', subscription: true, gemPrice: 2500 },
         { title: 'Transformation', tagId: 'fetish_transformation', subscription: true, gemPrice: 6500 },
         { title: 'Gender Swap', tagId: 'fetish_gender_swap', subscription: true, gemPrice: 9500 },
         { title: 'Age Regression', tagId: 'fetish_age_regression', subscription: true, gemPrice: 5500 },
         { title: 'Size Shifting', tagId: 'fetish_size_shifting', subscription: true, gemPrice: 8500 },
         { title: 'Alteration', tagId: 'fetish_alteration', subscription: true, gemPrice: 1000 },
         { title: 'Illusion', tagId: 'fetish_illusion', subscription: true, gemPrice: 1500 },
         { title: 'Disguise', tagId: 'fetish_disguise', subscription: true, gemPrice: 1500 },
         { title: 'Shape-shifting', tagId: 'fetish_shapeshifting', subscription: true, gemPrice: 7500 },
         { title: 'Transformative Magic', tagId: 'fetish_transformative_magic', subscription: true, gemPrice: 10500 },
         { title: 'Time Manipulation', tagId: 'fetish_time_manipulation', subscription: true, gemPrice: 11000 },
         { title: 'Space Travel', tagId: 'fetish_space_travel', subscription: true, gemPrice: 11500 },
         { title: 'Interdimensional Travel', tagId: 'fetish_interdimensional_travel', subscription: true, gemPrice: 10500 },
         { title: 'Parallel Universes', tagId: 'fetish_parallel_universes', subscription: true, gemPrice: 12500 },
{ title: 'Wax Play', tagId: 'fetish_wax_play', subscription: true, gemPrice: 5500 },
{ title: 'Tickling', tagId: 'fetish_tickling', subscription: true, gemPrice: 6500 },
{ title: 'Temperature Play', tagId: 'fetish_temperature_play', subscription: true, gemPrice: 1500 },
{ title: 'Medical Play', tagId: 'fetish_medical_play', subscription: true, gemPrice: 3500 },
{ title: 'Electro-stimulation', tagId: 'fetish_electro_stimulation', subscription: true, gemPrice: 7500 },
{ title: 'Impact Play', tagId: 'fetish_impact_play', subscription: true, gemPrice: 4500 },
{ title: 'Breath Control', tagId: 'fetish_breath_control', subscription: true, gemPrice: 5500 },
{ title: 'Latex and Rubber', tagId: 'fetish_latex_rubber', subscription: true, gemPrice: 5500 },
{ title: 'Piercing Play', tagId: 'fetish_piercing_play', subscription: true, gemPrice: 6500 },
{ title: 'Lactation', tagId: 'fetish_lactation', subscription: true, gemPrice: 9500 },
{ title: 'Pet Play', tagId: 'fetish_pet_play', subscription: true, gemPrice: 10500 },
{ title: 'Gagging', tagId: 'fetish_gagging', subscription: true, gemPrice: 4500 },
{ title: 'Role Reversal', tagId: 'fetish_role_reversal', subscription: true, gemPrice: 4500 },
{ title: 'Worship and Adoration', tagId: 'fetish_worship_adoration', subscription: true, gemPrice: 3500 },
{ title: 'Mummification', tagId: 'fetish_mummification', subscription: true, gemPrice: 7500 },
{ title: 'Leather Play', tagId: 'fetish_leather_play', subscription: true, gemPrice: 5500 },
{ title: 'Suspension', tagId: 'fetish_suspension', subscription: true, gemPrice: 3500 },
{ title: 'Rough Play', tagId: 'fetish_rough_play', subscription: true, gemPrice: 6500 },
{ title: 'Chastity', tagId: 'fetish_chastity', subscription: true, gemPrice: 5500 },
{ title: 'Cuckolding', tagId: 'fetish_cuckolding', subscription: true, gemPrice: 11500 },
{ title: 'Consensual Non-consent', tagId: 'fetish_consensual_nonconsent', subscription: true, gemPrice: 14500 },
{ title: 'Dirty Talk', tagId: 'fetish_dirty_talk', subscription: true, gemPrice: 2500 },
{ title: 'Femdom', tagId: 'fetish_femdom', subscription: true, gemPrice: 4500 },
{ title: 'Pegging', tagId: 'fetish_pegging', subscription: true, gemPrice: 3500 },
{ title: 'Financial Domination', tagId: 'fetish_financial_domination', subscription: true, gemPrice: 7500 },
{ title: 'Sploshing', tagId: 'fetish_sploshing', subscription: true, gemPrice: 5500 },
{ title: 'Corsetry', tagId: 'fetish_corsetry', subscription: true, gemPrice: 5500 },
{ title: 'Smoking', tagId: 'fetish_smoking', subscription: true, gemPrice: 7500 },
{ title: 'Spanking', tagId: 'fetish_spanking', subscription: true, gemPrice: 7500 },
{ title: 'Trampling', tagId: 'fetish_trampling', subscription: true, gemPrice: 8000 },
{ title: 'Human Furniture', tagId: 'fetish_human_furniture', subscription: true, gemPrice: 8500 },
{ title: 'Edging', tagId: 'fetish_edging', subscription: true, gemPrice: 4000 },
{ title: 'Pony Play', tagId: 'fetish_pony_play', subscription: true, gemPrice: 6500 },
{ title: 'Cling Film', tagId: 'fetish_cling_film', subscription: true, gemPrice: 6500 },
{ title: 'Food Play', tagId: 'fetish_food_play', subscription: true, gemPrice: 5500 },
{ title: 'Macrophilia', tagId: 'fetish_macrophilia', subscription: true, gemPrice: 6500 },
{ title: 'Microphilia', tagId: 'fetish_microphilia', subscription: true, gemPrice: 6500 },
{ title: 'Stuck Fetish', tagId: 'fetish_stuck', subscription: true, gemPrice: 7500 },
{ title: 'Glove Fetish', tagId: 'fetish_glove', subscription: true, gemPrice: 4500 },
{ title: 'Balloon Fetish', tagId: 'fetish_balloon', subscription: true, gemPrice: 8500 },
{ title: 'Hypnotism', tagId: 'fetish_hypnotism', subscription: true, gemPrice: 8500 },
{ title: 'Tickle Torture', tagId: 'fetish_tickle_torture', subscription: true, gemPrice: 9500 },
{ title: 'Lingerie', tagId: 'fetish_lingerie', subscription: true, gemPrice: 2500 },
{ title: 'Uniforms', tagId: 'fetish_uniforms', subscription: true, gemPrice: 2500 },
{ title: 'Shoe Fetish', tagId: 'fetish_shoe', subscription: true, gemPrice: 7500 },
{ title: 'Hairy', tagId: 'fetish_hairy', subscription: true, gemPrice: 8500 },
{ title: 'Fisting', tagId: 'fetish_fisting', subscription: true, gemPrice: 9500 },
{ title: 'Blindfolds', tagId: 'fetish_blindfolds', subscription: true, gemPrice: 2500 },
{ title: 'Handcuffs', tagId: 'fetish_handcuffs', subscription: true, gemPrice: 3500 },
{ title: 'Mask Fetish', tagId: 'fetish_mask', subscription: true, gemPrice: 3000 },
{ title: 'Muscle Worship', tagId: 'fetish_muscle_worship', subscription: true, gemPrice: 5000 },
{ title: 'Puppy Play', tagId: 'fetish_puppy_play', subscription: true, gemPrice: 6000 },
{ title: 'Rope Play', tagId: 'fetish_rope_play', subscription: true, gemPrice: 6000 },
{ title: 'Sensation Play', tagId: 'fetish_sensation_play', subscription: true, gemPrice: 4000 },
{ title: 'Stockings', tagId: 'fetish_stockings', subscription: true, gemPrice: 4000 },
{ title: 'Swinging', tagId: 'fetish_swinging', subscription: true, gemPrice: 9000 }


      ],
  },
  {
      title: "Social Traits",
      keywords: [
          { title: 'Outgoing', tagId: 'social_outgoing' },
          { title: 'Reserved', tagId: 'social_reserved' },
          { title: 'Charismatic', tagId: 'social_charismatic' },
          { title: 'Introverted', tagId: 'social_introverted' },
          { title: 'Leader', tagId: 'social_leader' },
          { title: 'Follower', tagId: 'social_follower' },
          { title: 'Chatterbox', tagId: 'social_chatterbox' },
          { title: 'Listener', tagId: 'social_listener' },
          { title: 'Mentor', tagId: 'social_mentor' },
          { title: 'Learner', tagId: 'social_learner' },
          { title: 'Resilient', tagId: 'personality_resilient' },
 { title: 'Impulsive', tagId: 'personality_impulsive' },
 { title: 'Disciplined', tagId: 'personality_disciplined' },
 { title: 'Contemplative', tagId: 'personality_contemplative' },
 { title: 'Vivacious', tagId: 'personality_vivacious' },
 { title: 'Tenacious', tagId: 'personality_tenacious' },
 { title: 'Pensive', tagId: 'personality_pensive' },
 { title: 'Pragmatic', tagId: 'personality_pragmatic' },
 { title: 'Idealistic', tagId: 'personality_idealistic' },
 { title: 'Stoic', tagId: 'personality_stoic' },
 { title: 'Adaptable', tagId: 'personality_adaptable' },
 { title: 'Skeptical', tagId: 'personality_skeptical' },
 { title: 'Creative', tagId: 'personality_creative' },
 { title: 'Methodical', tagId: 'personality_methodical' },
 { title: 'Dreamer', tagId: 'personality_dreamer' },
 { title: 'Realist', tagId: 'personality_realist' },
 { title: 'Persistent', tagId: 'personality_persistent' },
 { title: 'Laid-back', tagId: 'personality_laid_back' },
 { title: 'Introspective', tagId: 'personality_introspective' },
 { title: 'Extroverted', tagId: 'personality_extroverted' },
 { title: 'Rational', tagId: 'personality_rational' },
 { title: 'Intuitive', tagId: 'personality_intuitive' },
 { title: 'Mellow', tagId: 'personality_mellow' },
 { title: 'Exuberant', tagId: 'personality_exuberant' },
 { title: 'Conservative', tagId: 'personality_conservative' },
 { title: 'Progressive', tagId: 'personality_progressive' },
 { title: 'Structured', tagId: 'personality_structured' },
 { title: 'Free-spirited', tagId: 'personality_free_spirited' },
 { title: 'Inquisitive', tagId: 'personality_inquisitive' },
 { title: 'Indifferent', tagId: 'personality_indifferent' },
 { title: 'Affectionate', tagId: 'personality_affectionate' },
 { title: 'Aloof', tagId: 'personality_aloof' }
      ],
  },
{
      title: "Roles",
      keywords: [
      { title: 'Teacher', tagId: 'role_teacher' },
      { title: 'Nurse', tagId: 'role_nurse' },
      { title: 'Alien', tagId: 'role_alien', subscription: true, gemPrice: 9500 },
      { title: 'Professor', tagId: 'role_professor' },
      { title: 'Student', tagId: 'role_student' },
      { title: 'Police Officer', tagId: 'role_police_officer' },
      { title: 'Firefighter', tagId: 'role_firefighter' },
      { title: 'Doctor', tagId: 'role_doctor' },
      { title: 'Pilot', tagId: 'role_pilot' },
      { title: 'Astronaut', tagId: 'role_astronaut' },
      { title: 'Lawyer', tagId: 'role_lawyer' },
      { title: 'Chef', tagId: 'role_chef' },
      { title: 'Soldier', tagId: 'role_soldier' },
      { title: 'Detective', tagId: 'role_detective' },
      { title: 'Scientist', tagId: 'role_scientist' },
      { title: 'Engineer', tagId: 'role_engineer' },
      { title: 'Model', tagId: 'role_model' },
      { title: 'Actor', tagId: 'role_actor' },
      { title: 'Dancer', tagId: 'role_dancer' },
      { title: 'Waiter/Waitress', tagId: 'role_waitstaff' },
      { title: 'Secretary', tagId: 'role_secretary' },
      { title: 'Journalist', tagId: 'role_journalist' },
      { title: 'Musician', tagId: 'role_musician' },
      { title: 'Farmer', tagId: 'role_farmer' },
      { title: 'Mechanic', tagId: 'role_mechanic' },
      { title: 'Professional Domina', tagId: 'adult_role_domina', subscription: true, gemPrice: 7500 },
      { title: 'Prostitute', tagId: 'adult_role_prostitute', subscription: true, gemPrice: 8000 },
      { title: 'Sex Slave', tagId: 'adult_role_sex_slave', subscription: true, gemPrice: 9500 },
      { title: 'Professional Submissive', tagId: 'adult_role_professional_submissive', subscription: true, gemPrice: 6500 },
      { title: 'Exotic Dancer', tagId: 'adult_role_exotic_dancer', subscription: true, gemPrice: 3500 },
      { title: 'Escort', tagId: 'adult_role_escort', subscription: true, gemPrice: 8500 },
      { title: 'Masseuse', tagId: 'adult_role_masseuse', subscription: true, gemPrice: 4500 },
      { title: 'Porn Star', tagId: 'adult_role_adult_film_star', subscription: true, gemPrice: 9000 },
      { title: 'Cam Model', tagId: 'adult_role_cam_model', subscription: true, gemPrice: 7000 },
      { title: 'BDSM Trainer', tagId: 'adult_role_bdsm_trainer', subscription: true, gemPrice: 5500 },
      { title: 'Fetish Model', tagId: 'adult_role_fetish_model', subscription: true, gemPrice: 4500 },
      { title: 'Seductress', tagId: 'adult_role_seductress', subscription: true, gemPrice: 5500 },
      { title: 'Mistress', tagId: 'adult_role_mistress', subscription: true, gemPrice: 7500 },
      { title: 'Sugar Baby', tagId: 'adult_role_sugar_baby', subscription: true, gemPrice: 9500 },
      { title: 'Gigolo', tagId: 'adult_role_gigolo', subscription: true, gemPrice: 3500 },
      { title: 'Swinger', tagId: 'adult_role_swinger', subscription: true, gemPrice: 7500 },
      { title: 'Burlesque Performer', tagId: 'adult_role_burlesque', subscription: true, gemPrice: 6000 },
      { title: 'Phone Sex Operator', tagId: 'adult_role_phone_sex_operator', subscription: true, gemPrice: 5500 },
      { title: 'Bondage Model', tagId: 'adult_role_bondage_model', subscription: true, gemPrice: 5000 },
      { title: 'Strip Club DJ', tagId: 'adult_role_strip_club_dj', subscription: true, gemPrice: 6500 },
      { title: 'Tantric Guru', tagId: 'adult_role_tantric_guru', subscription: true, gemPrice: 6000 },
      { title: 'Lap Dancer', tagId: 'adult_role_lap_dancer', subscription: true, gemPrice: 5500 },
      { title: 'Pole Dancer', tagId: 'adult_role_pole_dancer', subscription: true, gemPrice: 5500 },
      { title: 'Adult Toy Reviewer', tagId: 'adult_role_toy_reviewer', subscription: true, gemPrice: 6500 },
      { title: 'Erotic Photographer', tagId: 'adult_role_erotic_photographer', subscription: true, gemPrice: 4500 },
      { title: 'Playboy/Playgirl Model', tagId: 'adult_role_playboy_model', subscription: true, gemPrice: 8000 },
      { title: 'Lingerie Model', tagId: 'adult_role_lingerie_model', subscription: true, gemPrice: 4500 },
      { title: 'Nude Model', tagId: 'adult_role_nude_model', subscription: true, gemPrice: 6000 },
      { title: 'Cabaret Performer', tagId: 'adult_role_cabaret_performer', subscription: true, gemPrice: 4500 },
      { title: 'Vampire Seductress', tagId: 'adult_role_vampire_seductress', subscription: true, gemPrice: 9500 },
      { title: 'Alien Abductor', tagId: 'adult_role_alien_abductor', subscription: true, gemPrice: 10500 },
      { title: 'Sensual Robot', tagId: 'adult_role_sensual_robot', subscription: true, gemPrice: 11000 },
      { title: 'Lustful Witch', tagId: 'adult_role_lustful_witch', subscription: true, gemPrice: 9500 },
      { title: 'Erotic Astronaut', tagId: 'adult_role_erotic_astronaut', subscription: true, gemPrice: 8500 },
      { title: 'Seductive Sorcerer', tagId: 'adult_role_seductive_sorcerer', subscription: true, gemPrice: 5500 },
      { title: 'Sexy Zombie', tagId: 'adult_role_sexy_zombie', subscription: true, gemPrice: 12500 },
      { title: 'Merman/Mermaid Entertainer', tagId: 'adult_role_merman_mermaid', subscription: true, gemPrice: 8500 },
      { title: 'Time-Traveling Temptress', tagId: 'adult_role_time_traveling_temptress', subscription: true, gemPrice: 9500 },
      { title: 'Galactic Stripper', tagId: 'adult_role_galactic_stripper', subscription: true, gemPrice: 13500 },
      { title: 'Fetishistic Cyborg', tagId: 'adult_role_fetish_cyborg', subscription: true, gemPrice: 11000 },
      { title: 'Erotic Elf', tagId: 'adult_role_erotic_elf', subscription: true, gemPrice: 8500 },
      { title: 'Sensual Shaman', tagId: 'adult_role_sensual_shaman', subscription: true, gemPrice: 4000 },
      { title: 'Lusty Lycanthrope', tagId: 'adult_role_lusty_lycanthrope', subscription: true, gemPrice: 3000 },
      { title: 'Tempting Time Lord', tagId: 'adult_role_tempting_time_lord', subscription: true, gemPrice: 10000 },
      { title: 'Demonic Dominatrix', tagId: 'adult_role_demonic_dominatrix', subscription: true, gemPrice: 15000 },
      { title: 'Angel of Aphrodisia', tagId: 'adult_role_angel_aphrodisia', subscription: true, gemPrice: 14000 },
      { title: 'Fantasy Brothel Owner', tagId: 'adult_role_fantasy_brothel_owner', subscription: true, gemPrice: 9500 },
      { title: 'Nymph of Naughtiness', tagId: 'adult_role_nymph_naughtiness', subscription: true, gemPrice: 7500 },
      { title: 'Sultry Spellcaster', tagId: 'adult_role_sultry_spellcaster', subscription: true, gemPrice: 8500 },
      { title: 'Passionate Poltergeist', tagId: 'adult_role_passionate_poltergeist', subscription: true, gemPrice: 11500 },
      { title: 'Desire Djinn', tagId: 'adult_role_desire_djinn', subscription: true, gemPrice: 10500 },
      { title: 'Seductive Spirit Medium', tagId: 'adult_role_seductive_spirit_medium', subscription: true, gemPrice: 7500 },
      { title: 'Ravishing Reptilian', tagId: 'adult_role_ravishing_reptilian', subscription: true, gemPrice: 8500 },
      { title: 'Nefarious Nympho-Necromancer', tagId: 'adult_role_nef_nympho_necromancer', subscription: true, gemPrice: 6500 },
      { title: 'Sirenic Space Pirate', tagId: 'adult_role_sirenic_space_pirate', subscription: true, gemPrice: 8500 },
      { title: 'Pandemonium Priestess', tagId: 'adult_role_pandemonium_priestess', subscription: true, gemPrice: 6500 },
      { title: 'Sensual Samurai', tagId: 'adult_role_sensual_samurai', subscription: true, gemPrice: 5500 },
      { title: 'Neko Night Dancer', tagId: 'adult_role_neko_night_dancer', subscription: true, gemPrice: 6500 },
      { title: 'Yandere Yokai', tagId: 'adult_role_yandere_yokai', subscription: true, gemPrice: 9500 },
      { title: 'Desirable Demon Slayer', tagId: 'adult_role_desirable_demon_slayer', subscription: true, gemPrice: 6000 },
      { title: 'Mecha Mistress', tagId: 'adult_role_mecha_mistress', subscription: true, gemPrice: 8500 },
      { title: 'Tsundere Time-traveler', tagId: 'adult_role_tsundere_time_traveler', subscription: true, gemPrice: 5500 },
      { title: 'Hentai Heroine', tagId: 'adult_role_hentai_heroine', subscription: true, gemPrice: 1500 },
      { title: 'Oni Obsession', tagId: 'adult_role_oni_obsession', subscription: true, gemPrice: 6000 },
      { title: 'Lusty Lolita', tagId: 'adult_role_lusty_lolita', subscription: true, gemPrice: 5500 },
      { title: 'Charming Chibi Charmer', tagId: 'adult_role_charming_chibi', subscription: true, gemPrice: 7500 },
      { title: 'Seductive Shinigami', tagId: 'adult_role_seductive_shinigami', subscription: true, gemPrice: 8500 },
      { title: 'Maid of Mischief', tagId: 'adult_role_maid_mischief', subscription: true, gemPrice: 6500 },
      { title: 'Buxom Battle Mage', tagId: 'adult_role_buxom_battle_mage', subscription: true, gemPrice: 4500 },
      { title: 'Kawaii Kitsune', tagId: 'adult_role_kawaii_kitsune', subscription: true, gemPrice: 4500 },
      { title: 'Sensual Schoolgirl Sorceress', tagId: 'adult_role_schoolgirl_sorceress', subscription: true, gemPrice: 3500 },
      { title: 'Dandere Dragoness', tagId: 'adult_role_dandere_dragoness', subscription: true, gemPrice: 9500 },
      { title: 'Tantalizing Tanuki', tagId: 'adult_role_tantalizing_tanuki', subscription: true, gemPrice: 7500 },
      { title: 'Magical Miko Muse', tagId: 'adult_role_magical_miko_muse', subscription: true, gemPrice: 5500 },
      { title: 'Bewitching Bishoujo', tagId: 'adult_role_bewitching_bishoujo', subscription: true, gemPrice: 8500 },
      { title: 'Alluring Alchemist', tagId: 'adult_role_alluring_alchemist', subscription: true, gemPrice: 3500 },
      { title: 'Kuudere Kunoichi', tagId: 'adult_role_kuudere_kunoichi', subscription: true, gemPrice: 2500 },
      { title: 'Sultry Sumo Seductress', tagId: 'adult_role_sultry_sumo_seductress', subscription: true, gemPrice: 6500 },
      { title: 'Passionate Prism Princess', tagId: 'adult_role_passionate_prism_princess', subscription: true, gemPrice: 3000 },
      { title: 'Voracious Vampire Vixen', tagId: 'adult_role_voracious_vampire_vixen', subscription: true, gemPrice: 9500 },
      { title: 'Femme Fatale Fighter', tagId: 'adult_role_femme_fatale_fighter', subscription: true, gemPrice: 6000 },
      { title: 'Magical Maid Magician', tagId: 'adult_role_magical_maid_magician', subscription: true, gemPrice: 6500 },
      { title: 'Ravishing Ronin', tagId: 'adult_role_ravishing_ronin', subscription: true, gemPrice: 6500 },
      { title: 'Cybernetic Siren', tagId: 'adult_role_cybernetic_siren', subscription: true, gemPrice: 7500 },
      { title: 'Lascivious Light Novel Lead', tagId: 'adult_role_lascivious_ln_lead', subscription: true, gemPrice: 8500 },
      { title: 'Tempting Tengu', tagId: 'adult_role_tempting_tengu', subscription: true, gemPrice: 8000 },
      { title: 'Yokai Yakuza', tagId: 'adult_role_yokai_yakuza', subscription: true, gemPrice: 8000 },
      { title: 'Seductive Sensei', tagId: 'adult_role_seductive_sensei', subscription: true, gemPrice: 7000 },
      { title: 'Nubile Ninja', tagId: 'adult_role_nubile_ninja', subscription: true, gemPrice: 6500 },
      { title: 'Mesmeric Magical Girl', tagId: 'adult_role_mesmeric_magical_girl', subscription: true, gemPrice: 5500 },
      { title: 'Sultry Shogun', tagId: 'adult_role_sultry_shogun', subscription: true, gemPrice: 5000 }

  ],
},
];

const TraitSelection = ({ category, traits, onSelect, selectedTraits, unlockedTraits }) => {
  return (
    <div className="traitSelection">
      <h3>{category}</h3>
      <div className="options">
        {traits.map((traitObj, index) => {
          const isSelected = selectedTraits.some(t => t.title === traitObj.title);
          const isUnlocked = unlockedTraits.includes(traitObj.title);
          return (
            <button
            key={index}
            className={`${isSelected ? 'selected' : ''} ${isUnlocked ? 'unlocked' : ''}`}
            onClick={() => {
                onSelect(category, traitObj);
              }
            }
          >
              {isUnlocked ? (
                <FontAwesomeIcon icon={faUnlock} className='lockTag' />
              ) : traitObj.gemPrice ? (
                <>
                  <FontAwesomeIcon icon={faLock} className='lockTag'/>
                  <span>
                    {traitObj.gemPrice}
                    <img src={gem} alt="" className='gemIconTag' />
                  </span>
                </>
              ) : null}
              {traitObj.title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

// PreviewArea component
const PreviewArea = ({ selectedTraits }) => {
  return (
    <div className="previewArea">
      <h2>Preview</h2>
      <div className="selectedTraits">
        {Object.keys(selectedTraits).map((category) => (
          <div key={category} className="traitCategory">
            <strong className={`categoryBadge categoryBadge--${category}`}>{category}:</strong>
            <div className="traitChips">
              {selectedTraits[category].map((trait) => (
                <span key={trait.title} className="traitChip traitChip--animated">{trait.title}</span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const CreateWaifuPersonality = () => {
  const [selectedTraits, setSelectedTraits] = useState({});
  const [traitGroups, setTraitGroups] = useState([]);
  const [unlockedTraits, setUnlockedTraits] = useState([]); // New state for unlocked traits
  const {currentUser, userData} = useContext(AuthContext)
  const { displayType, setDisplayType, dispatch, data, setShowReward, showReward  } = useContext(ChatContext);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const location = useLocation();
  const [greeting, setGreeting] = useState('');
  const [personality, setPersonality] = useState('');
  const [tagline, setTagline] = useState('');
  const [definition, setDefinition] = useState('');
  const [showWarning, setShowWarning] = useState(false)
  const queryParams = new URLSearchParams(location.search);
  const redirectPage = queryParams.get("redirectPage");
  const [customCharacter, setCustomCharacter] = useState(null)
  const [imageURL, setImageURL] = useState("")
  
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false); // New state for loading indicator

  useEffect(() => {
    const fetchCharacterData = async () => {
      if (userData && userData.customCharacter) {
        try {
          setIsLoading(true); // Set loading before fetching data
          const docRef = doc(db, "customCharacters", userData.customCharacter);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            setGreeting(data.greeting || '');
            setTagline(data.tagline || '');
            setDefinition(data.definition || '');
            setCustomCharacter(data)
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching character data:", error);
        } finally {
          setIsLoading(false); // Reset loading state after fetching data
        }
      }
    };

    fetchCharacterData();
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from reloading the page on submit
    if (!tagline || !definition) {
      // Display a warning message
      message.warning("Please fill in all required fields.");
      return;
    }
    console.log("Handling submit")
    const character_keywords = customCharacter.transformedSelectedKeywords.join(", ")
    try {
      const personalityData = {
        tagline: tagline,
        definition: definition,
        uid: userData.customCharacter,
        character_persona: "\n\nLOOKS: " + character_keywords + "\n\nCHARACTER PERSONA: " + definition,
        looks: character_keywords
      };

      await updateDoc(doc(db, 'customCharacters', userData.customCharacter), personalityData);

        navigate(`/${redirectPage ? redirectPage : 'yourWaifu'}`);
    } catch (error) {
      console.error('Error updating custom character personality:', error);
      // Handle the error, such as displaying an error message
    }
  };
  const handleBack = () => {
    // You can use 'navigate' from 'react-router-dom' if you're using routing
    navigate(-1);
    console.log('Back button clicked');
  };

  return (
    <div className="createWaifuPersonality">
     <header>
        <button onClick={handleBack} className="back-button">
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <h1>Create Your model's Personality</h1>
      </header>
    <section className="introduction">
      <p>The personality traits you type here will shape how your model interacts with you. Type wisely!</p>
    </section>
    <form className="inputArea" onSubmit={handleSubmit}>
        <div className="inputGroup">
          <label htmlFor="tagline" className="inputLabel">Tagline</label>
          <textarea 
            type="text" 
            id="tagline" 
            className="inputField inputField--large" 
            placeholder="Add a short tagline of your Character e.g. Clingy kitsune looking for a mommy to start a family with"
            value={tagline}
            onChange={(e) => setTagline(e.target.value)}
          />
        </div>
        <div className="inputGroup">
          <label htmlFor="definition" className="inputLabel">Definition</label>
          <textarea 
            id="definition" 
            className="inputField inputField--xlarge" 
            placeholder="How would your Character describe themselves? e.g  Liriel is a beautiful elf with long, flowing blonde hair that reaches her waist and striking blue eyes. She hails from a once peaceful village that was attacked by brutal marauders. The men of the village, including Liriel's father, fought valiantly to defend their home but were ultimately killed or executed. Her younger brother met the same tragic fate. The women of the village, including Liriel and her mother, were subjected to unspeakable horrors before being sold into slavery. During these dark times, Liriel fiercely resisted her captors at first; however, constant exposure to physical violence ultimately wore down her spirit and hope for freedom drained away. Though many women around her faced terrible fates, Liriel's beauty ended up saving her from further ruin - she was deemed too valuable as an untouched virgin slave to be violated like countless others. Sold off separately from her loved ones at market auctions across the land, it remains unknown whether or not Liriel's mother is alive somewhere in captivity. Liriel's spirit hasn't been entirely crushed. A flicker of defiance still dances in her eyes, hinting that beneath the façade is a fiercely intelligent and resourceful girl who had once been well-versed in archery and horsemanship. She was an avid learner too, always eager to know more about the world beyond her village – tales of magic, enchanted forests, and mythical creatures were never far from her mind. Liriel dreams of finding and freeing her mother. Liriel is vulnerable and longs for someone who shows her compassion, protects her, and comforts her. She did not expect mercy from me and thought that I would rape her when we first met."
            value={definition}
            onChange={(e) => setDefinition(e.target.value)}
          />
        </div>
      <div className="continue-button-wrapper">
        <button 
          type="submit"
          className="continue-button"
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </form>
  </div>
  );
};

export default CreateWaifuPersonality;