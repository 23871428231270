import React, { useRef, useState, useEffect } from 'react';
import { FaPlay, FaPause, FaVolumeMute, FaVolumeUp, FaRedo } from 'react-icons/fa';

const BlurredVideo = ({ videoSrc, isBlurred = true }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isEnded, setIsEnded] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    const handleContextMenu = (e) => e.preventDefault();
    const handleEnded = () => {
      setIsPlaying(false);
      setIsEnded(true);
    };

    video.addEventListener('contextmenu', handleContextMenu);
    video.addEventListener('ended', handleEnded);

    // Console detection (this is not foolproof)
    const detectDevTools = () => {
      const widthThreshold = window.outerWidth - window.innerWidth > 160;
      const heightThreshold = window.outerHeight - window.innerHeight > 160;
      if (widthThreshold || heightThreshold) {
        // Console might be open
        alert("Developer tools detected. Please close them to continue.");
        // You could also pause the video, blur it, or take other actions here
      }
    };

    window.addEventListener('resize', detectDevTools);
    setInterval(detectDevTools, 1000); // Check periodically

    return () => {
      video.removeEventListener('contextmenu', handleContextMenu);
      video.removeEventListener('ended', handleEnded);
      window.removeEventListener('resize', detectDevTools);
    };
  }, []);


  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
      setIsEnded(false);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const toggleMute = () => {
    const video = videoRef.current;
    video.muted = !video.muted;
    setIsMuted(video.muted);
  };

  const restartVideo = () => {
    const video = videoRef.current;
    video.currentTime = 0;
    video.play();
    setIsPlaying(true);
    setIsEnded(false);
  };

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        playsInline
        muted={isMuted}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {isBlurred && <div className="blur-overlay"></div>}
      <div className="watermark">AI GENERATED</div>
      <div className="controls">
        <button onClick={togglePlayPause} className="control-btn play-pause-btn">
          {isPlaying && !isBlurred ? <FaPause /> : <FaPlay />}
        </button>
        {!isBlurred && (
        <button onClick={toggleMute} className="control-btn mute-btn">
  {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
        </button>)}
        {isEnded && !isBlurred && (
          <button onClick={restartVideo} className="control-btn restart-btn">
            <FaRedo />
          </button>
        )}
      </div>
    </div>
  );
};

export default BlurredVideo;