import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Modal } from 'antd';
import styles from '../../../styles/StoryStore.module.scss';
import gem from "../../../img/gem.png";
import { ChatContext } from '../../../context/ChatContext';
import { AuthContext } from '../../../context/AuthContext';
import {notification } from 'antd';
import { deleteDoc } from "firebase/firestore";
import {
  arrayUnion,
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
  Timestamp,
  updateDoc,
  collection,
  addDoc,
  runTransaction,
  getDocs,
  arrayRemove,
  onSnapshot
} from "firebase/firestore";
import { analytics, db, storage } from "../../../firebase";
import { getAnalytics, logEvent } from "firebase/analytics";
import InfoButton from '../../Common/InfoButton';
import CustomStoryForm from './CustomStoryForm';

function StoryStore({isOpen, handleClose, character, user}) {
  const defaultStory = {
    img: 'linear-gradient(180deg, #5C75CC 0%, #203378 100%)',
    title: 'Default (No Story)',
    description: '',
    content: '',
    price: 0,
    id: 'default',
  };

  const [selectedStory, setSelectedStory] = useState(null);
  const [storyEntries, setStoryEntries] = useState([]);
  const { showWarning, setShowWarning} = useContext(ChatContext);
  const [showInfo, setShowInfo] = useState(false);
  const [showCustomStoryForm, setShowCustomStoryForm] = useState(false);
  const {currentUser, userData} = useContext(AuthContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [selectedStoryId, setSelectedStoryId] = useState(defaultStory.id);
  const [hasFetchedStories, setHasFetchedStories] = useState(false);
  const buyStory = async (story) => {
    if (story.id === 'default') {
      return;
    }

    if (user.gems >= story.price) {
      const userDoc = doc(db, 'users', user.uid);
      await updateDoc(userDoc, {
        gems: user.gems - story.price,
        boughtStories: arrayUnion(story.id)
      });

      notification.success({
        message: 'Purchase Successful!',
        description: `You have successfully purchased the story: ${story.title}`,
      });
      setSelectedStory(story);
      // Inside the buyStory function after purchase is successful
    // Inside the buyStory function after purchase is successful
    logEvent(analytics, 'buy_story', {
      id: story.id,
      title: story.title,
      price: story.price
    });

    } else {
      setShowWarning(true);
    }
  };

  const openStory = (story) => {
    if (story.id !== 'default') {
      setSelectedStory(story);
    }
  };

  const closeStory = () => {
    setSelectedStory(null);
  };

  const selectStoryForChat = async (story) => {
    if (story.id === 'default') {
      return;
    }

    if (selectedStoryId === story.id) {
      setSelectedStoryId(defaultStory.id);
      const combinedId = user.uid > character.uid ? user.uid + character.uid : character.uid + user.uid;
      const chatDoc = doc(db, 'chats', combinedId);
      await updateDoc(chatDoc, {
        selectedStory: null
      });
    } else {
      setSelectedStoryId(story.id);
      const combinedId = user.uid > character.uid ? user.uid + character.uid : character.uid + user.uid;
      const chatDoc = doc(db, 'chats', combinedId);
      await updateDoc(chatDoc, {
        selectedStory: story
      });
    }
  };

  const replaceCharacterToken = (text, characterId) => {
    return text.replace(/\[character\]/g, characterId);
  }
  const editStory = async (storyId) => {
    // Fetch the story
    const storyDoc = doc(db, 'customStories', storyId);
    const storySnapshot = await getDoc(storyDoc);
    const storyData = storySnapshot.data();
    setInitialValues({
      title: storyData.title,
      description: storyData.description,
      content: storyData.description,
      img: storyData.img,
      id: storyData.id
    });

    setIsEditMode(true);
    setShowCustomStoryForm(true);
  };
  const createOrUpdateStory = async (story, storyId, gemCost) => {
    // Check if user has enough gems or if we're updating a story
    if (user.gems >= gemCost) {
      const userDoc = doc(db, 'users', user.uid);
      // Subtract gem only when it's creating a new story
        await updateDoc(userDoc, {
          gems: user.gems - gemCost,
        });
  
      const customStoriesCollection = collection(db, 'customStories');
      let newStoryId;
  
      if (storyId) { // If an ID is given, we're updating an existing story
        await updateDoc(doc(db, 'customStories', storyId), {
          ...story,
          createdAt: serverTimestamp(), // You might want to remove this line if you don't want to update the createdAt field
          ownerID: user.uid,
          bg: story.img,
          img: story.img,
          storyPrompt: story.description.trim()
        });
        newStoryId = storyId;
      } else { // Otherwise, we're creating a new story
        const docRef = await addDoc(customStoriesCollection, {
          ...story,
          createdAt: serverTimestamp(),
          ownerID: user.uid,
          bg: story.img,
          img: story.img,
          storyPrompt: story.description.trim()
        });
  
        // After the document is added, get its ID and update the document with this ID
        newStoryId = docRef.id;
        await setDoc(doc(db, 'customStories', newStoryId), { id: newStoryId }, { merge: true });
  
        // Add the new story ID to the user's boughtStories list
        await updateDoc(userDoc, {
          boughtStories: arrayUnion(newStoryId)
        });
      }
  
      notification.success({
        message: storyId ? 'Story Updated Successfully!' : 'Custom Story Created!',
        description: `You have successfully ${storyId ? 'updated' : 'created'} a ${storyId ? 'story' : 'custom story'}: ${story.title}`,
      });
// Inside the createOrUpdateStory function after story is created or updated
logEvent(analytics, storyId ? 'update_story' : 'create_story', {
  id: newStoryId,
  title: story.title
});
    } else {
      setShowWarning(true);
    }
  };
  const deleteStory = (storyId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this story?',
      onOk: async () => {
        // Perform your deletion operation
        // If successful, show a success notification
         // Then we delete the story from Firestore.
    const storyRef = doc(db, 'customStories', storyId);
    await deleteDoc(storyRef);

    // And remove it from the boughtStories array in the user's document.
    const userDoc = doc(db, 'users', user.uid);
    await updateDoc(userDoc, {
      boughtStories: arrayRemove(storyId)
    });

    // After that, we remove the story from our local state.
    const updatedStoryEntries = storyEntries.filter(story => story.id !== storyId);
    setStoryEntries(updatedStoryEntries);
        notification.success({
          message: 'Deleted Successfully!',
          description: `Story has been deleted.`,
        });
        // Handle errors too!
      },
      onCancel: () => {
        console.log('Cancel');
      },
    });
  }
  useEffect(() => {
    if (!user) {
      return;
    }
console.log(storyEntries)
    const fetchStories = async () => {
      const userDoc = doc(db, 'users', user.uid);
      const userSnapshot = await getDoc(userDoc);
      let userData = userSnapshot.data();

      if (userData) {
        if (!userData.boughtStories) {
          userData.boughtStories = [];
          await updateDoc(userDoc, {
            boughtStories: userData.boughtStories,
          });
        }
      } else {
        console.error("userData is undefined");
      }

      const allStoriesCollection = collection(db, 'allStories');
      const allStorySnapshot = await getDocs(allStoriesCollection);

      const customStoriesCollection = collection(db, 'customStories');
      const customStorySnapshot = await getDocs(customStoriesCollection);

      const allStoriesData = allStorySnapshot.docs.map((doc) => doc.data());
      const customStoriesData = customStorySnapshot.docs
        .map((doc) => ({ ...doc.data(), price: 0, bought: true }))
        .filter((story) => story.ownerID === user.uid); // Only show the stories owned by the user

      const storiesData = [...customStoriesData, ...allStoriesData].map(
        (story) => ({
          ...story,
          id: story.id,
          title: replaceCharacterToken(story.title || "", character.displayName),
          description: replaceCharacterToken(story.description || "", character.displayName),
          content: replaceCharacterToken(story.content || story.description, character.displayName),
        })
      );

      setStoryEntries(storiesData);
      console.log("entries", storyEntries)
    };

    const fetchSelectedStory = async () => {
      if (!character || !character.uid || !user || !user.uid) return;
      const combinedId = user.uid > character.uid ? user.uid + character.uid : character.uid + user.uid;
      const chatDoc = doc(db, 'chats', combinedId);
      const chatSnapshot = await getDoc(chatDoc);
      const chatData = chatSnapshot.data();
      if (chatData && chatData.selectedStory && userData.boughtStories.includes(chatData.selectedStory.id)) {
          setSelectedStoryId(chatData.selectedStory.id);
      }
  };
    

    const customStoriesQuery = collection(db, 'customStories');
    const unSub = onSnapshot(customStoriesQuery, () => {
      fetchStories();
    });

    fetchStories();
    fetchSelectedStory();

    // Cleanup function
    return () => unSub();

}, [character, user, db]);



  return (
    <div className={`${styles['story-sidebar']} ${isOpen ? styles.open : ""}`}>
      <button className={styles.close} onClick={handleClose}>x</button>
      <InfoButton 
        title="Info" 
        content={[`Explore limitless possibilities with the "Stories" feature! Immerse yourself and your model in AI-generated settings, bringing your wildest dreams to life. Choose from a variety of pre-designed "stories". Unleash your imagination today! 

\nNote: Purchased Stories will be unlocked for all of your models.`]} 
      />
      <h1 className={styles.headline}>Stories</h1>
      <div className={styles.storyStore}>
        {!selectedStory ? (
          <div className={styles.storyGrid}>
<div
              className={`${styles.story} ${styles.customStoryButton}`}
              onClick={() => {setIsEditMode(false);setShowCustomStoryForm(true)}}
              style={{ backgroundImage: 'url("https://cdn.discordapp.com/attachments/1022569580754898944/1126243120519069787/00010-456972200.png")' }} // Replace with your custom story button image
            >
              <div className={styles.storyInfo}>
                <h4>Create Custom Story</h4>
                <p> The Goddess Sienna Invites you to create your own Story!</p>
              </div>
            </div>
            <div
              className={`${styles.story} ${selectedStoryId === defaultStory.id ? styles.selected : ""}`}
              style={{ backgroundImage: defaultStory.img.includes('linear-gradient') ? defaultStory.img : `url(${defaultStory.img})` }}
            >
              <div className={styles.storyInfo}>
                <h4>{defaultStory.title}</h4>
                <p>{defaultStory.description}</p>
              </div>
            </div>
            {storyEntries.map(story => (
              <div
  key={story.id}
  className={`${styles.story} ${user.boughtStories && user.boughtStories.includes(story.id) ? (selectedStoryId === story.id ? styles.selected : styles.bought) : ""}`}
  onClick={() => openStory(story)}
>
  {story.img.includes('linear-gradient') ? (
    <div style={{ backgroundImage: story.img }}></div>
  ) : (
    <img src={story.img} alt={story.title} className={styles.storyImage} />
  )}
  <div className={styles.storyInfo}>
    <h4>{story.title}</h4>
    <p>{story.description}</p>
    {story.ownerID === user.uid && (
      <>
        <button className={styles.editButton} onClick={(event) => { event.stopPropagation(); editStory(story.id) }}>
          <FontAwesomeIcon icon={faEdit} />
        </button>
        <button className={styles.deleteButton} onClick={(event) => { event.stopPropagation(); deleteStory(story.id) }}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </>
    )}
  </div>
</div>
))}
          </div>
        ) : (
          <div className={styles.fullStory}>
  <button onClick={closeStory} className={styles.closeStory}><FontAwesomeIcon icon={faArrowLeft} /></button>
  <img className={styles.fullStoryImage} src={selectedStory.img} alt={selectedStory.title} />
  {!user.boughtStories?.includes(selectedStory.id) && (
    <div className={styles.gemPrice}>{selectedStory.price}<img src={gem} alt="" className='gem'></img></div>
  )}
  <h2>{selectedStory.title}</h2>
  <p>{selectedStory.content}</p>
  {user.boughtStories?.includes(selectedStory.id) ? (
    <button
      className={selectedStoryId === selectedStory.id ? styles.unselectButton : styles.selectButton}
      onClick={() => selectStoryForChat(selectedStory)}
    >
      {selectedStoryId === selectedStory.id ? 'Unselect' : 'Select'}
    </button>
  ) : (
    <button className={styles.selectButton} onClick={() => buyStory(selectedStory)}>Buy</button>
  )}
</div>
        )}
      </div>
      <CustomStoryForm isEditMode={isEditMode} onSubmit={createOrUpdateStory} visible={showCustomStoryForm} setVisible={setShowCustomStoryForm} initialValues={initialValues} storyId={isEditMode ? initialValues.id : null} />
    </div>
  );
}
export default StoryStore;