import React, { useState, useContext, useEffect, useRef, useCallback, memo, Suspense } from 'react';
import { useNavigate, Link } from "react-router-dom";
import bubbles from "../../img/bubbles.svg"
import { AuthContext } from "../../context/AuthContext";
import { logEvent, auth} from "../../firebase";
import { ChatContext } from "../../context/ChatContext";
import { CharacterContext } from '../../context/CharacterContext';
import gem from "../../img/gem.png"
import lock from "../../img/lock.png"
import { Modal, Button } from "antd";
import MainNav from '../../components/Common/MainNav'
import { v4 as uuid } from "uuid";
import { handleAIResponse, generatePrompt } from "../../AIConnector";
import { writeBatch } from "firebase/firestore"; // Import writeBatch
import { v4 as uuidv4 } from 'uuid';
import { signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import FilterBar from '../../components/FilterBar';
import "../../styles/characterCard.scss"
import UnlockedCharacters from '../../components/UnlockedCharacters';
import { useLocation } from 'react-router-dom';
import UnlockedFeature from '../../components/UnlockedFeature';
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
  arrayUnion,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";
import { db, logGemPurchase } from "../../firebase";
import { Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createChatId } from '../../helpers';
import NoGemsModal from '../../components/NoGemsModal';
import DailyRewardsModal from '../../components/Chat/DailyRewards';
import SignInOut from '../../components/Auth/SignInOut';
import {faStar } from '@fortawesome/free-solid-svg-icons';
const FilterableContentPage = () => {
  const hasNewMessageSentRef = useRef(false);
  const [initialForm, setInitialForm] = useState("register")
  const { currentUser, userData, noUser } = useContext(AuthContext);
  const [showReward, setShowReward] = useState(false)
  const { displayType, setDisplayType, dispatch, data, characterData } = useContext(ChatContext);
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showBuyCharacterModal, setShowBuyCharacterModal] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [lockAmount, setLockAmount] = useState(true)
  const [UID, setUID] = useState("")
  const loadingChatRef = useRef(false);
  const [postLink, setPostLink] = useState("")
  const [hasNewMessageSent, setHasNewMessageSent] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const { charactersData, charactersLoading} = useContext(CharacterContext);
  const [loadingChat, setLoadingChat] = useState(false)
  const [favorites, setFavorites] = useState(userData && userData.favorites ? userData.favorites : [])
  const navigate = useNavigate()
  const location = useLocation();
  const [visibility, setVisibility] = useState(false);
  const [filteredContentState, setFilteredContentState] = React.useState([]);
  // Call this method to toggle visibility and show the animation
  const toggleVisibility = () => {
    setVisibility(!visibility);
  };
  const getRandomCharacter = (userData) => {
    const chatIds = userData?.chats;
    console.log("chat_ids", chatIds)
    if (!chatIds || chatIds.length === 0) return null;
  
    const randomIndex = Math.floor(Math.random() * chatIds.length);
    let chatId = chatIds[randomIndex];
  
    // The character's uid is the part of the chatId that isn't the user's uid
    const characterUid = chatId.replace(userData?.uid, '');
    chatId = currentUser.uid > characterUid ? currentUser.uid + characterUid : characterUid + currentUser.uid
    return { characterUid, chatId };
};
const getSelectedCategoryFromURL = () => {
  const searchParams = new URLSearchParams(location.search);
  console.log('lkasdjlksajd', searchParams.get('source'))
  return searchParams.get('category') || 'recommended';
};
const getSelectedAdsThingsFromURL = () => {
  const searchParams = new URLSearchParams(location.search);
  return {
    source: searchParams.get('source'),
    subid: searchParams.get('subid')
  };
};
const [selectedCategory, setSelectedCategory] = useState(getSelectedCategoryFromURL());
const [adsThings, setAdsThings] = useState(getSelectedAdsThingsFromURL());

console.log('adsThings', adsThings)

useEffect(() => {
  const searchParams = new URLSearchParams(location.search);
  if (selectedCategory !== 'defaultCategory') {
    searchParams.set('category', selectedCategory);
  } else {
    searchParams.delete('category');
  }
  navigate({ search: searchParams.toString() }, { replace: true });
}, [selectedCategory, navigate]);
// Function to generate a UUID
const generateTempSessionId = () => {
  return uuidv4();
};

// Function to update Firestore
const updateFirestore = async (db, tempSessionId) => {
  try {
    await setDoc(doc(db, "users", tempSessionId), {
      uid: tempSessionId,
      gems: 20,
      subscription: "None",
      FormData: { name: "Oniichan" }, 
      memories: [] 
    }, { merge: true });
  } catch (error) {
    console.error("Error updating Firestore: ", error);
  }
};
const isSigningIn = useRef(false);
useEffect(() => {
  const unsubscribeFromAuthState = onAuthStateChanged(auth, async (user) => {
    if (user) {
      // Logic for authenticated user
    } else {
      // Logic for no user
      if (!isSigningIn.current) {
        isSigningIn.current = true;
        signInAnonymously(auth)
          .then(async (userCredential) => {
            const user = userCredential.user;
            console.log('Anonymous Firebase user created successfully:', user.uid);
            localStorage.setItem('tempSessionId', user.uid);
            await updateFirestore(db, user.uid);
            isSigningIn.current = false;
          })
          .catch((error) => {
            console.error('Error creating anonymous Firebase user:', error);
            isSigningIn.current = false;
          });
      }
    }
  });

  if (userData && userData.gems === undefined && userData.uid) {
    setDoc(doc(db, "users", currentUser.uid), {
      uid: currentUser.uid,
      gems: 100,
      subscription: "None",
      email: currentUser.email,
      xp: 0,       // Assuming xp, maxXP, level are defined elsewhere and valid
      maxXP: 100, 
      level: 1  
    }).catch(error => console.error("Error setting document:", error));
  }

  return () => {
    unsubscribeFromAuthState(); // Cleanup subscription on unmount
  };
}, [currentUser, noUser, updateFirestore]);
  const getLastMessageTime = async (chatId) => {
    const chatDocRef = doc(db, "chats", chatId);
    const chatDocSnapshot = await getDoc(chatDocRef);
    console.log("messages", chatDocSnapshot.data())
    const messages = chatDocSnapshot.data()?.messages || [];
    
    if (messages.length === 0) return null;
  
    const lastMessage = messages[messages.length - 1];
    return lastMessage.date;
  };
  
  const handleLocked = (amount, uid) => {
    setUID(uid)
    setLockAmount(amount)
    setShowBuyCharacterModal(true)
  }
  const handleLockedWithoutLogin = () => {
    setDisplayType("locked")
    setShowSignUp(true)
   }


   const StartChat = async (characterUID, withPictures) => {
    loadingChatRef.current = true;
    setLoadingChat(true);
    // Fetch sessionId; it will be either currentUser's UID or tempSessionId
    console.log(localStorage.getItem('tempSessionId'))
    const sessionId = currentUser.uid;
    console.log("start chat session id", sessionId)
    // Initialize the Firestore batch
    const batch = writeBatch(db);
    
    // Only log analytics if a currentUser exists
    if (currentUser) {
      window.trackExoClickConversion('openChat');
      logEvent(`clicked_character_${characterUID}`);
    }
    const collectionName = withPictures ? "characters" : "newCharacters" 
    try {
      // Fetch character from Firestore
      var character = null;
      const q = query(
        collection(db, collectionName),
        where("uid", "==", characterUID)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        character = doc.data();
      });
    
      // Create or update the chat group
      console.log("character id", character, "user id ", currentUser)
      const combinedId = createChatId(currentUser.uid, character.uid);
      const userDocRef = doc(db, "users", sessionId);
      if (!userData?.chats?.includes(combinedId)) {
        batch.update(userDocRef, {
          chats: arrayUnion(combinedId)
        });
      }
      const chatDocRef = doc(db, "chats", combinedId);
      const chatDocSnapshot = await getDoc(chatDocRef);
      if (!chatDocSnapshot.exists() || chatDocSnapshot.data().messages === null) {
        batch.set(chatDocRef, { messages: [], id: combinedId});
      }
  
      // Commit the batch
      await batch.commit();
  
      console.log("Dispatch the character to the Redux store and navigate to the chat page")
      dispatch({ type: "CHANGE_USER", payload: character });
      setTimeout(() => {
        console.log("navigating to chat")
        navigate(`/chat?char=${character.uid}` + (withPictures ? "" : "&new=true"));
        setLoadingChat(false); // Set loadingChat to false after navigation
      }, 0); // You can adjust the timeout duration as needed
    } catch (error) {
      console.error(error);
      setLoadingChat(false); // Ensure loading state is reset in case of error
    }
  };
  const handleClick = useCallback((locked, lockAmount,uid, withPictures) => {
    const sessionId = currentUser.uid;
    console.log(sessionId)
    console.log(locked)
    logEvent("first character click")
    if (locked) {
      console.log("locked", locked)
      if(currentUser.isAnonymous === false)
      {
      handleLocked(lockAmount, uid);
      }
      else{
        handleLockedWithoutLogin()
      }
    } else {
      console.log("Start chat")
      StartChat(uid, withPictures);
    }
  }, [handleLocked, StartChat]);
  
  
  useEffect(() => {
    
    if((currentUser && !currentUser.isAnonymous && userData && !(userData?.FormData)))
    {
      navigate(`/multistepform`)
    }
  
    async function loadProgress() {
      if (currentUser) {
        const postLinkDoc = await getDoc(doc(db, 'quests', 'TwitterQuests'));
        if (postLinkDoc.exists()) {
          setPostLink(postLinkDoc.data().postLink);
          console.log("link1:", userData.lastClaimedPost, "link2:", postLink);
        }
      }
    }
  
    if (userData?.uid) {
      loadProgress();
    }


  }, [currentUser]);
  
  const filteredContent = React.useMemo(() => {
    const maxTotalCharacters = 500;
    let content = [];
    setFavorites(userData && userData.favorites ? userData.favorites : [])
    // Sort characters based on message count
    const sortedCharacters = [...charactersData].sort((a, b) => 
      b.messagesCount - a.messagesCount
    );
  
    // Implement priority queue (heap) operations
    const heap = {
      values: sortedCharacters,
      remove: function() {
        return this.values.shift();
      },
      isEmpty: function() {
        return this.values.length === 0;
      }
    };
  
    // Fill up the content with characters based on the selected category
    while (!heap.isEmpty() && content.length < maxTotalCharacters) {
      const character = heap.remove();
      if (selectedCategory === 'favorites') {
        // Filter characters to include only those in the favorites array
        if (favorites.includes(character.uid)) {
          content.push(character);
        }
      } else if (selectedCategory === 'all' || character.category.some(category => 
          category.toLowerCase().trim() === selectedCategory.toLowerCase().trim())) {
        content.push(character);
      }
    }
    setFilteredContentState(content);
    console.log("filteredContent", content);
    return content;
  }, [charactersData, selectedCategory])
  const imageLoadedCache = {};
  const onToggleFavorite = async (characterId) => {
    const userRef = doc(db, 'users', userData.uid); // Reference to the user's document in Firestore
    try {
      const currentFavorites = userData.favorites || [];
      const isFavorite = currentFavorites.includes(characterId);
      const updatedFavorites = isFavorite 
        ? currentFavorites.filter(id => id !== characterId) 
        : [...currentFavorites, characterId];

      await updateDoc(userRef, {
        favorites: updatedFavorites
      });
      setFavorites(updatedFavorites)
      // Update local state or context if necessary to reflect the change
      // This step depends on how you manage state in your application
    } catch (error) {
      console.error("Error updating favorites: ", error);
    }
  };
  const CharacterCard = memo(({
    name, image, description, messagesCount, custom, locked = false, lockAmount, uid, newMessages, isNew, characterLevel, withPictures, withVideos = false, isFavorite
  }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const updateScreenSize = () => setScreenWidth(window.innerWidth);
      window.addEventListener('resize', updateScreenSize);
      return () => window.removeEventListener('resize', updateScreenSize);
    }, []);
    const isLocked = locked && characterLevel > (userData.level || 1);
    const getImageUrl = () => {
      return screenWidth > 768 ? image.replace('verySmall', 'small') : image;
    };
  
    const imageUrl = React.useMemo(() => getImageUrl(), [screenWidth, image]);
  
    return (
      <div className={`swiper-slide ${locked ? 'locked' : ''}`} onClick={() => handleClick(isLocked, lockAmount, uid, withPictures)}>
 <div className={`character-slide-card ${withPictures ? 'with-picture' : ''}`}>
 <button
  className={`favorite-button ${isFavorite ? 'is-favorite' : ''}`} // Apply 'is-favorite' class if the item is favorited
  onClick={(event) => {
    event.stopPropagation(); // Prevent the click from bubbling up to parent elements
    onToggleFavorite(uid); // Toggle the favorite state
  }}
>
  <FontAwesomeIcon icon={isFavorite ? faStar : faStar} /> 
</button>
        {withPictures && <div className={`${withVideos ? 'video-badge' : 'picture-badge'}`}>{withVideos ? ("WITH VIDEOS") :("AI PICTURES & VIDEOS")}</div>}
          {isLocked ? (
            <div className="locked-overlay">
              <p className="unlock-requirement">Reach level {characterLevel} to unlock</p>
              <img
                onLoad={() => setImageLoaded(true)}
                className={`charater-avatar-image-locked ${imageLoaded ? 'visible' : ''}`}
                src={imageUrl}
                alt={name}
              />
              <div className="card-info">{name}</div>
              <div className="character-slide-info">{description}</div>
              <button className="unlock-button">
                Don't wait <span className="gems-amount">{lockAmount}<img className="textgem" src={gem} alt=""></img></span>
              </button>
            </div>
          ) : (
            <>
              {isNew && <div className="new-badge">NEW</div>}
              <img
                onLoad={() => setImageLoaded(true)}
                className={`charater-avatar-image ${imageLoaded ? 'visible' : ''}`}
                src={imageUrl}
                alt={name}
              />
              <div className="card-info">{name}</div>
              <div className="character-slide-info">{description}</div>
                      {/* Edit and Delete Buttons */}
        {/*<button onClick={() => onEditDescription(uid)}>Edit Description</button>
        <button onClick={() => onDeleteCharacter(uid)}>Delete</button>*/}
              {newMessages > 0 && <div className='newMessageIndicator'>{newMessages}</div>}
              {!custom && (
          <div className='card-footer'>
            <img src={bubbles} alt="" />{messagesCount}
          </div>
        )}
            </>
          )}
        </div>
      </div>
    );
  });
  const onEditDescription = async (uid) => {
    const newDescription = prompt("Enter the new description:");
    if (newDescription) {
      const characterDocRef = doc(db, "newCharacters", uid);
      await updateDoc(characterDocRef, {
        description: newDescription
      }).catch(error => console.error("Error updating description: ", error));
    }
  };
  const onDeleteCharacter = async (uid) => {
    if (window.confirm("Are you sure you want to delete this character?")) {
      const characterDocRef = doc(db, "newCharacters", uid);
      await deleteDoc(characterDocRef)
        .then(() => console.log("Character deleted"))
        .catch(error => console.error("Error deleting character: ", error));
    }
  };
  
  const handleUnlock = async (uid) => {
    console.log("handle Unlock");
  
    if (!userData || (userData.gems - lockAmount < 0)) {
      setShowBuyCharacterModal(false);
      setShowWarning(true);
      return;
    }
  
    await updateDoc(doc(db, "users", currentUser.uid), {
      gems: userData.gems - lockAmount
    }, { merge: true });
    
    logGemPurchase(currentUser.uid, lockAmount, "unlock character", uid);
    setShowBuyCharacterModal(false);
  
    // Update charactersLocked in userData
    const charactersLocked = [...userData.charactersLocked];
    const index = charactersLocked.findIndex(character => character.uid === uid);
  
    console.log("index found", index, "for", uid);
    if (index !== -1) {
      charactersLocked[index] = { ...charactersLocked[index], locked: false };
      await updateDoc(doc(db, 'users', currentUser.uid), { charactersLocked });
  
      // Update the filteredContentState to reflect the unlocked character
      const updatedFilteredContent = filteredContentState.map(character => {
        if (character.uid === uid) {
          console.log("character found", uid, { ...character, locked: false });
          return { ...character, locked: false };
        }
        return character;
      });
  
      setFilteredContentState(updatedFilteredContent);
    }
  };
  
  
  function formatTimeRemaining(date) {
    const remainingTime = (new Date(date.getTime() + 24 * 60 * 60 * 1000)) - new Date();
    const remainingHours = Math.floor(remainingTime / (60 * 60 * 1000));
    const remainingMinutes = Math.floor((remainingTime - remainingHours * 60 * 60 * 1000) / (60 * 1000));
    return `${padZero(remainingHours)}:${padZero(remainingMinutes)} h`;
  }
  const DayDifference = (date1, date2) => {
    const differenceInMilliseconds = Math.abs(date1 - date2);
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return differenceInDays;
  }
  function padZero(number) {
    return number.toString().padStart(2, '0');
  }
  const handleSelectCategory = (category) => {
setSelectedCategory(category)
  }
  return (
    <>
    <div>

            { loadingChat ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ) : (
        <div className='mainWrapper'>
        <MainNav/>
        {charactersLoading || (characterData && characterData.length < 10) || !currentUser? (
          <div style={{ justifyContent: "center", display: "flex", marginTop: "3rem" }}>
      <Spin size="large"/>
    </div>
  ) : (
    <div className='selectChat'>
      <div className="content">
      {/*<EmailVerificationPopup/>*/}
      <div className="banner">
      <i className="banner-icon fab fa-discord"></i>
      <a 
        href="https://discord.gg/Vu5Gaahe5M" 
        className="banner-link" 
        target="_blank" 
        rel="noreferrer"
      >
        Join our Discord community!
      </a>
    </div>
      {!currentUser.isAnonymous&& (
      <div className='dailyRewardTimer'>
      {userData?.lastClaimed ? 
  (DayDifference(userData.lastClaimed.toDate(), new Date()) > 0.99 ? 
    (<div className="dailyRewardButton" onClick={() => setShowReward(true)}>Claim Reward</div>) : 
    (<div>Next Daily Reward in {formatTimeRemaining(userData.lastClaimed.toDate())}</div>)
  ) : 
  (<div></div>)
}
<div className="dailyRewardButton" onClick={() => setShowReward(true)}>{userData?.lastClaimedPost && postLink ? (userData.lastClaimedPost === postLink +"?trackId=75767fg7t8z8h"? ("Earn Gems") :("New quest available")):("Earn Gems")}</div>
</div>
 )}
<FilterBar selectedCategory={selectedCategory} handleSelectCategory={handleSelectCategory}/>
      {filteredContentState.length === 0 ? (
    <div className='NoWaifus'>No models here yet</div>
  ) :(
        <div className="grid">
        {filteredContentState.map(({ name, image, description, category, messagesCount, locked, lockAmount, uid, newMessages, level, withPictures, withVideos }) => (
  <CharacterCard
    key={name}
    name={name}
    image={image}
    locked={locked}
    lockAmount={lockAmount}
    custom={category === "custom"}
    messagesCount={messagesCount}
    category={category}
    description={description ? description : "custom"}
    uid={uid}
    newMessages={newMessages}
    isNew={name === "Eva"}
    characterLevel={level}
    withPictures={withPictures}
    withVideos={withVideos}
    isFavorite={favorites.includes(uid)} // Check if the character is in the favorites array
  />
))}
      </div>
      )}
    </div>
    </div>
  )}
    </div>
      )}
      </div>
<Modal
  title="INFORMATION"
  visible={showBuyCharacterModal}
  onOk={handleUnlock}
  onCancel={() => setShowBuyCharacterModal(false)}
  footer={[
    <Button key="back" onClick={() => setShowBuyCharacterModal(false)}>
      No
    </Button>,
    <Button key="submit" type="primary" onClick={() => handleUnlock(UID)}>
      Yes
    </Button>,
  ]}
>
  <p>
    Would you like to unlock this character for {lockAmount}
    <img className="textgem" src={gem} alt=""></img>
  </p>
</Modal>
<SignInOut 
  isVisible={showSignUp}
  onClose={() => setShowSignUp(false)}
  initialForm="register"
  displayType={displayType}
/>
<NoGemsModal
  visible={showWarning}
  onCancel={() => setShowWarning(false)}
/>
{currentUser && !currentUser.isAnonymous && <DailyRewardsModal show={showReward} onClose={() => setShowReward(false)} onShow={() => setShowReward(true)}/> }
</>
  );
};

export default FilterableContentPage;
