import React, { useState, useContext, useEffect} from 'react'
import '../styles/SingleSubscriptionPanel.scss'; // Make sure to use the correct path to your SCSS file
import { useNavigate,  Redirect } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

export const SingleSubscriptionPanelNew = ({isSubscribed, currentPlan}) => {
  const { currentUser, userData } = useContext(AuthContext);
  const [selectedPlan, setSelectedPlan] = useState(currentPlan || 'annual'); // Initialize with current
  const [subscriptionTerms, setSubscriptionTerms] = useState('');
  const monthlyTerms = "You will be billed $9.99 per month until you cancel the subscription.";
  const annualTerms = "You will be billed $59.88 today which covers your subscription for the next 12 months. After 12 months, you will be automatically billed $59.88 annually until you cancel.";
  const navigate = useNavigate();
  const features = [
    { text: "8,250 gems/month", available: true },
    { text: "Unlimited Roleplay", available: true },
    { text: "Unlock All NSFW Content", available: true },
    { text: "Infinite Free Messages", available: true },
    { text: 'Unlock "Your model"', available: true },
    { text: "Advanced AI language Model", available: true },
    { text: "Unlock Voice Messages", available: true },
    { text: "All Features Unlocked", available: true },
    // Add more features as needed
  ];
  useEffect(() => {
    setSelectedPlan(currentPlan || 'annual');
  }, [currentPlan]);

  useEffect(() => {
    // Update subscription terms based on the selected plan
    setSubscriptionTerms(selectedPlan === 'annual' ? annualTerms : monthlyTerms);
  }, [selectedPlan]);

  const handlePlanSelection = (plan) => {
    if (!isSubscribed) {
      setSelectedPlan(plan);
    }
  };
  const OnSubscribe = () => {
    if (currentUser.isAnonymous)
      {
navigate("/login")
return
      }
      window.trackExoClickConversion('Lead')
    if(selectedPlan === "monthly")
    {
    navigate(`/checkout/Ultimate Plan/9.99/subscription/P-2DX28813MF715731AM2HJAQY`)
    }
    else{
      navigate(`/checkout/Annual Plan/59.88/subscription/P-1FE76679RH6498203M2HJBFY`)
    }
  }

  return (
    <div className='oniichat-plus-panel'>
      <div className="subscription-content">
        <h2>Onlychat Ultimate</h2>
        <ul className="features">
          {features.map((feature, index) => (
            <li key={index} className={feature.available ? '' : 'unavailable'}>
              {feature.text}
            </li>
          ))}
        </ul>
        {!isSubscribed && (
          <div className="plan-selection">
            <button
              className={`plan-option ${selectedPlan === 'annual' ? 'selected' : ''}`}
              onClick={() => handlePlanSelection('annual')}>
              Annual - Save 50% - $4.99/mo
            </button>
            <button
              className={`plan-option ${selectedPlan === 'monthly' ? 'selected' : ''}`}
              onClick={() => handlePlanSelection('monthly')}>
              Monthly - $9.99/mo
            </button>
          </div>
        )}
        {!isSubscribed && (
          <div className="termsText">
            <p>{subscriptionTerms}</p>
          </div>
        )}
        <div className="subscribe-button-container">
          {isSubscribed ? (
            <div className="subscribed-message">
              <p>You are currently subscribed to the {selectedPlan === 'annual' ? 'Annual' : 'Monthly'} plan.</p>
            </div>
          ) : (
            <button className="subscribe-button" onClick={OnSubscribe}>
              Subscribe Now
              {selectedPlan === 'annual' && <span className="badge">Save 50%</span>}
            </button>
          )}
        </div>
        {!isSubscribed && (
          <p className="cancel-info">Cancel anytime. Renews {selectedPlan === 'annual' ? 'annually' : 'monthly'}.</p>
        )}
      </div>
    </div>
  );
};