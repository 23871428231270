import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/Diary.scss";
import { AuthContext } from '../../../context/AuthContext';
import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { handleAIResponse } from '../../../AIConnector';
import { v4 as uuidv4 } from 'uuid';
import InfoButton from '../../Common/InfoButton';
function Diary({isOpen, handleClose, characterName, chatData}) {
  const [selectedEntry, setSelectedEntry] = useState(null);
  const {currentUser, userData} = useContext(AuthContext)
  const [diaryEntries, setDiaryEntries] = useState({});
  const entryCreating = useRef(false);
  const cancelToken = useRef(false);


  const selectEntry = (entry) => {
    setSelectedEntry(entry);
  };

  const closeEntry = () => {
    setSelectedEntry(null);
  };

  const getChatSinceDate = async (sinceDate) => {
    const messagesSinceDate = chatData.messages.filter(msg => {
      if (!msg.date) {
        console.error('Message missing timestamp:', msg);
        return false;
      }

      const msgDate = msg.date.toDate();
      return msgDate > sinceDate;
    });
    console.log(messagesSinceDate)
    return messagesSinceDate;
  };

  const containsNumbers = (text) => {
    return /\d/.test(text);
  };
  const getAllChats = async () => {
    if (chatData) {
      if(chatData && chatData.messages) {
        const allMessages = chatData.messages;
        console.log(allMessages)
        return allMessages;
      } else {
        console.log('No messages in the chat data');
        return [];
      }
    } else {
      console.log('Chat doc does not exist');
      return [];
    }
  };
  
  const createDiaryEntry = async (currentEntries) => {
    if (entryCreating.current) {
      cancelToken.current = true;
      console.log("Entry creation stopped");
      return;
    }
  
    console.log("Entry creation started");
    entryCreating.current = true;
    cancelToken.current = false;
  
    currentEntries[characterName] = currentEntries[characterName] || [];
  
    let messagesToInclude = currentEntries[characterName].length > 0 
      ? await getChatSinceDate(getLatestEntryDate(currentEntries[characterName])) 
      : await getAllChats();
  
    if (cancelToken.current) return;
  
    console.log("diary messages: ", messagesToInclude)
    if (messagesToInclude.length > 0) {
      const sortedMessages = sortMessagesByDate(messagesToInclude);
      const diaryEntryDate = getDiaryEntryDate(sortedMessages);
      const existingEntry = findEntryByDate(currentEntries[characterName], diaryEntryDate);
  
      if (!existingEntry) {
        const prompt = createPrompt(sortedMessages, currentEntries[characterName].length, characterName);
        const diaryEntryContent = await handleAIResponse(prompt);
  
        await createNewEntry(currentEntries, diaryEntryDate, diaryEntryContent);
      }
    }
  
    entryCreating.current = false;
  };
  
  const getLatestEntryDate = (entries) => {
    const latestEntryDate = new Date(entries.sort((a, b) => new Date(b.date) - new Date(a.date))[0].date);
    return latestEntryDate || new Date();
  };
  
  const sortMessagesByDate = (messages) => 
    messages.sort((a, b) => a.date.toMillis() - b.date.toMillis());
  
  const getDiaryEntryDate = (sortedMessages) => 
    sortedMessages[sortedMessages.length - 1].date.toDate().toLocaleDateString('en-US');
  
  const findEntryByDate = (entries, date) => 
    entries.find(entry => new Date(entry.date).toLocaleDateString('en-US') === date);
  
    const createPrompt = (sortedMessages, entries, characterName) => {
      let totalCharacters = 0;
      const mappedMessages = sortedMessages
        .map(msg => {
          const formattedMessage = formatMessage(msg);
          // Check if formatMessage(msg) returned a value
          if (formattedMessage) {
            // If adding this message would exceed the limit, return null to skip it
            if (totalCharacters + formattedMessage.length > 3000) return null;
            // Update the total characters counter
            totalCharacters += formattedMessage.length;
            // Return the formatted message
            return formattedMessage;
          }
          // Return null if formatMessage(msg) did not return a value
          return null;
        })
        .filter(Boolean)
        .join('\n');
      
      const lastDiaryEntry = getLastDiaryEntry(entries);
      
      return `${mappedMessages}
      ### Input: Last diary entry: ${lastDiaryEntry}
      #### Instruction: Write the ${entries.length}. diary entry as ${characterName} only about the above chat with up to 80 words.
      ### Response 
      Dear Diary,`;
    };
    
    const getLastDiaryEntry = (entries) => {
      if (entries.length > 0) {
        // Sort the entries by date, select the latest one, and return its content
        return entries.sort((a, b) => new Date(b.date) - new Date(a.date))[0].content;
      } else {
        // Return a default message if there are no previous entries
        return "No previous entries.";
      }
    };
  
    const formatMessage = (msg) => {
      let sender = containsNumbers(msg.senderId) 
          ? userData?.FormData?.name || "Onichan" 
          : msg.senderId;
      return msg.text !== '' && (!msg.locked || userData?.subscription !== 'None') 
          ? `${sender}: ${msg.text.trim()}` 
          : null;
  };
  
  const createNewEntry = async (currentEntries, diaryEntryDate, diaryEntryContent) => {
    const diaryEntry = {
      id: uuidv4(),
      content: diaryEntryContent,
      date: diaryEntryDate,
    };
  
    currentEntries[characterName].push(diaryEntry);
  
    const db = getFirestore();
    const userRef = doc(db, 'users', currentUser.uid);
  
    await setDoc(userRef, { diaryEntries: currentEntries }, { merge: true });
    setDiaryEntries(currentEntries);
  };
  
  
  

  useEffect(() => {
    console.log("chatData", chatData)
    const fetchDiaryEntries = async () => {
        let fetchedDiaryEntries = userData?.diaryEntries || {};

        if (!entryCreating.current) {
          await createDiaryEntry(fetchedDiaryEntries);
        }

        setDiaryEntries(fetchedDiaryEntries);
      
    };
  
    fetchDiaryEntries();
  }, []);


  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <button className="close" onClick={handleClose}>
        x
      </button>
      <InfoButton 
        title="Info" 
        content={[`In the "Diary" feature, your model will create a daily entry based on your conversations. As you chat more, the entries gradually become more personal, allowing you to learn more about your model through this function.`]} 
      />
      <h1>Diary</h1>
      <div className='diary'>
        {!selectedEntry ? (
          diaryEntries[characterName]?.length ? (  // Check if there are entries
            diaryEntries[characterName]?.map(entry => (
              <div key={entry.id} className="entry" onClick={() => selectEntry(entry)}>
              <p>{entry.content && typeof entry.content === 'string' ? entry.content.substring(0, 100) : 'Content not available'}...</p>
                <h4>{entry.date}</h4>
              </div>
            ))
          ) : (
            <p>No diary entries yet.</p> // Placeholder text for no entries
          )
        ) : (
          <div className="entry">
            <button onClick={closeEntry} className='closeEntry'><FontAwesomeIcon icon={faArrowLeft} /></button>
            <p>{selectedEntry.content}</p>
            <h4>{selectedEntry.date}</h4>
          </div>
        )}
      </div>
    </div>
  );
}

export default Diary;
